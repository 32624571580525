import {
  FETCH_APPS_LOADING,
  FETCH_APPS_SUCCESS,
  FETCH_APPS_ERROR,
  RESET_FETCH_APPS
} from '../actions/types';

const initialState = {
  loading: false,
  hasLoaded: false,
  error: null,
  data: []
};

export const connectedDecks = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_APPS_LOADING:
      return {
        ...initialState,
        loading: true,
        hasLoaded: false
      };
    case FETCH_APPS_SUCCESS:
      return {
        ...initialState,
        data: action.payload,
        loading: false,
        hasLoaded: true
      };
    case FETCH_APPS_ERROR:
      return {
        ...initialState,
        error: action.error,
        loading: false,
        hasLoaded: true
      };
    case RESET_FETCH_APPS:
      return {
        data: [],
        error: null,
        loading: false
      };
    default:
      return state;
  }
};
