/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { InfoCarouselModal } from './InfoCarouselModal';
import { updateProfileSettings } from '../../actions/profileSettings';
import { isUK } from '../../utils/common-methods';
import { iff } from '../../utils/iff';
import { getallOrgsList, isOrgListSuccesss } from '../../selectors/organizationListSelector';
import { createOrgList } from '../../actions/orgsList';
import { fetchUserProfile } from '../../actions/user';
import { setWhatsNewIsVisible } from '../../actions/index';

const InfoCarouselWrapper = (props) => {
  const { t } = useTranslation();
  const [isNewSignedupUser, setIsSignedupUser] = useState(false);

  useEffect(() => {
    setIsSignedupUser(props.orgsList.length < 1 && !props.user.tutorial_complete);
  }, [props.orgsList]);

  const carouselJson = {
    btnText: t('Next'),
    btnBack: t('Back'),
    lastBtnText: iff(props.user.auto_created_org_id, t('Finish'), t('Create Property')),
    onBtnClicked: () => null,
    children: [
      {
        image: 0,
        id: 'Welcome',
        title: iff(isUK(), t('Welcome'), t('Welcome to Cropwise!')),
        description: t(
          `Now you can use technology and innovation in farming. Let’s learn some basics before you start.`
        )
      },
      {
        image: 1,
        id: 'Products',
        title: iff(isUK(), t('Browse your Products'), t('Browse your Cropwise Products')),
        description: t(
          'To access all your Cropwise Products, use the navigation menu from anywhere.'
        )
      },
      {
        image: 2,
        id: 'Organization',
        title: t('Organization'),
        description: t(
          'When creating an organization you can manage all of your products, users, properties and seasons.'
        )
      },
      {
        image: 3,
        id: 'Properties',
        title: t('Properties'),
        description: t(
          'A digital representation of your farm, where you can register, define and manage the regions and fields of your property.'
        )
      },
      {
        image: 4,
        id: 'Season',
        title: t('Crop cycle'),
        description: t(
          'Easily set up planting and harvest dates to create a harvest cycle. Associate fields on your farm with crop cycles.'
        )
      },
      {
        image: 5,
        id: 'Solutions',
        title: iff(isUK(), t('Solutions'), t('Solutions Cropwise')),
        description: t(
          `Now that you've learned the basics, start using our custom solutions to improve your work!`
        )
      }
    ]
  };
  return (
    <InfoCarouselModal
      carouselJson={carouselJson}
      user={props.user}
      updateProfileSettings={props.updateProfileSettings}
      isNewSignedupUser={isNewSignedupUser}
      createOrg={props.createOrg}
      termsSuccess={props.termsSuccess}
      fetchUserDetails={props.fetchUserDetails}
      orgs={props.orgs}
      orgsList={props.orgsList}
      propertiesList={props.propertiesList}
      isWhatsNewVisible={props.isWhatsNewVisible}
      setWhatsNewIsVisible={props.setWhatsNewIsVisible}
    />
  );
};

InfoCarouselWrapper.propTypes = {
  user: PropTypes.object,
  orgsList: PropTypes.arrayOf(PropTypes.object),
  createOrg: PropTypes.func,
  isOrgListSuccesss: PropTypes.bool,
  updateProfileSettings: PropTypes.func,
  termsSuccess: PropTypes.bool.isRequired,
  fetchUserDetails: PropTypes.func,
  orgs: PropTypes.array,
  propertiesList: PropTypes.object,
  isWhatsNewVisible: PropTypes.bool,
  setWhatsNewIsVisible: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  return {
    user: state.user.userProfile,
    orgsList: getallOrgsList(state),
    isOrgListSuccesss: isOrgListSuccesss(state),
    orgs: get(state, 'orgsList.allOrgs.result', []),
    propertiesList: state.propertiesList.data,
    isWhatsNewVisible: get(state, 'header.isWhatsNewVisible', false)
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateProfileSettings: (userDetails, userId, includeExtended) =>
    dispatch(updateProfileSettings(userDetails, userId, includeExtended)),
  createOrg: (org) => dispatch(createOrgList(org)),
  fetchUserDetails: (isCache) => dispatch(fetchUserProfile(isCache)),
  setWhatsNewIsVisible: (isVisible) => dispatch(setWhatsNewIsVisible(isVisible))
});

export const InfoCarouselContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(InfoCarouselWrapper);
