import React from 'react';
import Zendesk, { ZendeskAPI } from 'react-zendesk';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { AppConstants } from '../../utils/app.constants';
import { getLanguageCode, getLocaleTempUsingCookies } from '../../utils/common-methods';

const STATUS = ['local', 'qa'].includes(AppConstants.ENVIRONMENT as string);

const StyledZendeskButton = styled.button`
  right: 15px;
  bottom: 7px;
  position: absolute;
  color: rgb(255, 255, 255);
  background: rgb(0, 0, 75);
  z-index: 99;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 18px;
`;

const ZENDESK_KEY = 'e3975b8f-6479-4be9-8f04-395360c62a56';

const setting = {
  color: {
    theme: '#00004b',
    launcher: '#eaeff8',
    button: '#eaeff8',
    launcherText: '#eaeff8',
  },
  zIndex: -1,
  prefill: {
    email: {
      value: 'isamu@voltron.com',
      readOnly: true
    }
  },
  launcher: {
    chatLabel: {
      'en-US': 'Need Help'
    }
  },
  contactForm: {
    fields: [{ id: 'description', prefill: { '*': '' } }]
  }
};

export const ZendeskSetLocale = () => {
  setTimeout(() => {
    ZendeskAPI('webWidget', 'setLocale', getLanguageCode(getLocaleTempUsingCookies())); // pt-BR fr de en
  }, 100);
};

ZendeskSetLocale();

export const ZendeskWidget = () => {
  if (!STATUS) {
    return null;
  }
  return <Zendesk zendeskKey={ZENDESK_KEY} {...setting} />;
};

export const ZendeskCustomBtn = () => {
  const { t } = useTranslation();

  if (!STATUS) {
    return null;
  }
  return (
    <Tooltip title={t('Help')}>
      <StyledZendeskButton
        id='zendeskButton'
        onClick={() => {
          (window as any).zE('webWidget', 'updateSettings', {
            zIndex: 9999
          });
          (window as any).zE('webWidget', 'toggle');
        }}>
        ?
      </StyledZendeskButton>
    </Tooltip>
  );
};

const zendeskInit = () => {
  const script = document.createElement('script');
  script.src = `${setTimeout(() => {
    (window as any).zE('webWidget:on', 'open', function () {
      const zendeskButton = document.getElementById('zendeskButton');
      if (zendeskButton) {
        zendeskButton.style.zIndex = '-1';
      }
    });
    (window as any).zE('webWidget:on', 'close', function () {
      const launcher = document.getElementById('launcher');
      if (launcher) {
        launcher.style.zIndex = '-1';
      }
      const zendeskButton = document.getElementById('zendeskButton');
      if (zendeskButton) {
        zendeskButton.style.zIndex = '99';
      }
    });
  }, 4000)}`;

  document.body.appendChild(script);
};

if (STATUS) {
  try {
    zendeskInit();
  } catch (e) {
    // do nothing...
  }
}
