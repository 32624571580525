import cloneDeep from 'lodash/cloneDeep';
import {
  ADD_MANAGED_USER_LOADING,
  ADD_MANAGED_USER_SUCCESS,
  ADD_MANAGED_USER_ERROR,
  GET_USER_DETAILS_LOADING,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_ERROR,
  INVITE_USER_LOADING,
  INVITE_USER_SUCCESS,
  INVITE_USER_ERROR,
  EDIT_USER_PERMISSION_LOADING,
  EDIT_USER_PERMISSION_SUCCESS,
  EDIT_USER_PERMISSION_ERROR,
  EDIT_USER_DETAILS_LOADING,
  EDIT_USER_DETAILS_SUCCESS,
  EDIT_USER_DETAILS_ERROR,
  REMOVE_USER_LOADING,
  REMOVE_USER_SUCCESS,
  REMOVE_USER_ERROR,
  GET_USER_DETAILS_BY_STATUS_LOADING,
  GET_USER_DETAILS_BY_STATUS_SUCCESS,
  GET_USER_DETAILS_BY_STATUS_ERROR
} from '../actions/types';

const initialState = {
  loading: false,
  error: null,
  managedUserDetails: {},
  userDetails: {},
  invitedUserDetails: {},
  editedPermission: [],
  removedUser: [],
  updatedUserDetails: {},
  invitedUsers: {},
  editUserDetailsSuccess: false,
  userDetailsRemoveSuccess: false
};

export const userAccessManagement = (state = initialState, action) => {
  switch (action.type) {
    case ADD_MANAGED_USER_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case ADD_MANAGED_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        managedUserDetails: action.payload
      };
    case ADD_MANAGED_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case GET_USER_DETAILS_LOADING:
      return {
        ...state,
        loading: action.payload,
        editUserDetailsSuccess: false,
        userDetailsRemoveSuccess: false
      };
    case GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        userDetails: action.payload
      };
    case GET_USER_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case INVITE_USER_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case INVITE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        invitedUserDetails: action.payload
      };
    case INVITE_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case EDIT_USER_PERMISSION_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case EDIT_USER_PERMISSION_SUCCESS:
      return {
        ...state,
        loading: false,
        editedPermission: action.payload
      };
    case EDIT_USER_PERMISSION_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case EDIT_USER_DETAILS_LOADING:
      return {
        ...state,
        loading: action.payload,
        editUserDetailsSuccess: false,
        userDetailsRemoveSuccess: false
      };
    case EDIT_USER_DETAILS_SUCCESS:
      var indexUser = state.userDetails.content.findIndex((item) => item.id === action.payload.id);
      const updateUsers = cloneDeep(state.userDetails.content);
      updateUsers.splice(indexUser, 1, action.payload);

      return {
        ...state,
        loading: false,
        updatedUserDetails: action.payload,
        userDetails: { ...state.userDetails, content: updateUsers },
        editUserDetailsSuccess: true
      };
    case EDIT_USER_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case REMOVE_USER_LOADING:
      return {
        ...state,
        loading: action.payload,
        userDetailsRemoveSuccess: false,
        editUserDetailsSuccess: false
      };
    case REMOVE_USER_SUCCESS:
      var users = state.userDetails.content.filter((item) => item.id !== action.payload.userId);
      return {
        ...state,
        loading: false,
        invitedUserDetails: action.payload.data,
        userDetails: { ...state, content: users },
        userDetailsRemoveSuccess: true
      };
    case REMOVE_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case GET_USER_DETAILS_BY_STATUS_LOADING:
      return {
        ...state,
        loading: action.payload,
        invitedUsers: {}
      };
    case GET_USER_DETAILS_BY_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        invitedUsers: action.payload
      };
    case GET_USER_DETAILS_BY_STATUS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
        invitedUsers: {}
      };
    default:
      return state;
  }
};
