import {
  FETCH_DETECT_FIELDS_BOUNDRIES_ERROR,
  FETCH_DETECT_FIELDS_BOUNDRIES_LOADING,
  FETCH_DETECT_FIELDS_BOUNDRIES_SUCCESS,
  FETCH_FIELD_DETECTION_SOURCE_LIST_LOADING,
  FETCH_FIELD_DETECTION_SOURCE_LIST_SUCCESS,
  FETCH_FIELD_DETECTION_SOURCE_LIST_ERROR
} from '../actions/types';

export const initialState = {
  loading: false,
  error: null,
  data: [],
  providerName: null,
  isEmptyList: false
};

export const initialStateForFieldDetectSourceList = {
  isLoading: false,
  isError: false,
  fieldDetectionSourceListError: null,
  fieldDetectionSourceListData: []
};

export const detectFields = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DETECT_FIELDS_BOUNDRIES_LOADING:
      return {
        ...state,
        loading: true
      };
    case FETCH_DETECT_FIELDS_BOUNDRIES_SUCCESS:
      return {
        ...state,
        data: action.payload.list,
        providerName: action.payload.providerName,
        isEmptyList: action.payload.isEmptyList.length === 0,
        loading: false
      };
    case FETCH_DETECT_FIELDS_BOUNDRIES_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false
      };
    default:
      return state;
  }
};

export const fieldDetectionSourceList = (
  state = initialStateForFieldDetectSourceList,
  { type, data, error }
) => {
  switch (type) {
    case FETCH_FIELD_DETECTION_SOURCE_LIST_LOADING:
      return {
        ...state,
        isLoading: true
      };

    case FETCH_FIELD_DETECTION_SOURCE_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        fieldDetectionSourceListData: data
      };

    case FETCH_FIELD_DETECTION_SOURCE_LIST_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        fieldDetectionSourceListError: error
      };

    default:
      return state;
  }
};
