/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { AppConstants } from '../../utils/app.constants';

import {
  StyledCarousel,
  StyledPage,
  StyledWelcomeImage,
  StyledTitle,
  StyledDescription,
  StyledButtonContainer,
  StyledButtonNext,
  StyledButtonBack
} from './InfoCarousel.style';
import { iff } from '../../utils/iff';
import { history, URL_CONSTANTS } from '../../utils/history';

/**
 * corousel back button
 * @param {*} props
 * @returns
 */
export const CorouselBackButton = (props) => {
  const { i, carouselJson, onBackButtonClicked } = props;
  return (
    <StyledButtonBack
      type='default'
      onClick={() => onBackButtonClicked(i)}
      data-test-id='styled-page-back-button'>
      {iff(i > 0 && i <= carouselJson.children.length - 1, carouselJson.btnBack)}
    </StyledButtonBack>
  );
};
CorouselBackButton.propTypes = {
  i: PropTypes.number,
  carouselJson: PropTypes.object,
  onBackButtonClicked: PropTypes.func
};

/**
 * corousel next button
 * @param {*} props
 * @returns
 */
export const CorouselNextButton = (props) => {
  const {
    i,
    carouselJson,
    child,
    setDisabledBtnVal,
    isNewSignedupUser,
    onNextClicked,
    isNextLoading
  } = props;
  return (
    <StyledButtonNext
      disabled={isNewSignedupUser && child.id === 'Organization' && setDisabledBtnVal()}
      loading={isNextLoading}
      onClick={() => onNextClicked(i, child)}
      data-test-id='styled-page-next-button'>
      {iff(i < carouselJson.children.length - 1, carouselJson.btnText, carouselJson.lastBtnText)}
    </StyledButtonNext>
  );
};
CorouselNextButton.propTypes = {
  i: PropTypes.number,
  carouselJson: PropTypes.object,
  child: PropTypes.object,
  setDisabledBtnVal: PropTypes.func,
  isNewSignedupUser: PropTypes.bool,
  isNextLoading: PropTypes.bool,
  onNextClicked: PropTypes.func
};

/**
 * create org input box
 * @param {*} props
 * @returns
 */
export const CreateOrgInputCorousel = (props) => {
  const { getFieldDecorator, t, onInputFieldChange } = props;
  return (
    <Form
      name='basic'
      initialValues={{ remember: true }}
      style={{ width: '60%' }}
      data-test-id='styled-page-form'>
      <Form.Item>
        {getFieldDecorator('username', {
          rules: [
            {
              max: AppConstants.VALIDATIONS.NAME_MAX_LENGTH,
              message: t('Organization name cannot be longer than {{MAX}} characters', {
                MAX: AppConstants.VALIDATIONS.NAME_MAX_LENGTH
              })
            },
            { required: true, message: t('Please enter Name!') }
          ]
        })(
          <Input
            placeholder={t('Enter your organization name')}
            size='large'
            onChange={(e) => onInputFieldChange(e)}
            data-test-id='styled-page-input-box'
          />
        )}
      </Form.Item>
    </Form>
  );
};
CreateOrgInputCorousel.propTypes = {
  getFieldDecorator: PropTypes.func,
  onInputFieldChange: PropTypes.func,
  t: PropTypes.func
};

/**
 * corousel content wrapper
 * @param {*} props
 * @returns
 */
export const CorouselPageWrapper = (props) => {
  const {
    child,
    setDisabledBtnVal,
    isNewSignedupUser,
    isNextLoading,
    i,
    carouselJson,
    getFieldDecorator,
    onInputFieldChange,
    t,
    onNextClicked,
    onBackButtonClicked
  } = props;

  return (
    <StyledPage data-test-id='styled-page'>
      <StyledWelcomeImage image={child.image} data-test-id='styled-page-img' />
      <StyledTitle data-test-id='styled-page-title'>{child.title}</StyledTitle>
      <StyledDescription data-test-id='styled-page-desc'>{child.description}</StyledDescription>
      <StyledButtonContainer data-test-id='styled-page-buttons'>
        <CorouselNextButton
          i={i}
          carouselJson={carouselJson}
          child={child}
          setDisabledBtnVal={setDisabledBtnVal}
          isNewSignedupUser={isNewSignedupUser}
          onNextClicked={onNextClicked}
          isNextLoading={isNextLoading}
        />
        {isNewSignedupUser && child.id === 'Organization' && (
          <CreateOrgInputCorousel
            getFieldDecorator={getFieldDecorator}
            onInputFieldChange={onInputFieldChange}
            t={t}
          />
        )}
        {i > 0 && i <= carouselJson.children.length - 1 && (
          <CorouselBackButton
            i={i}
            carouselJson={carouselJson}
            onBackButtonClicked={onBackButtonClicked}
          />
        )}
      </StyledButtonContainer>
    </StyledPage>
  );
};

CorouselPageWrapper.propTypes = {
  child: PropTypes.object,
  setDisabledBtnVal: PropTypes.func,
  isNewSignedupUser: PropTypes.bool,
  isNextLoading: PropTypes.bool,
  i: PropTypes.number,
  carouselJson: PropTypes.object,
  getFieldDecorator: PropTypes.func,
  onInputFieldChange: PropTypes.func,
  t: PropTypes.func,
  onNextClicked: PropTypes.func,
  carousel: PropTypes.object,
  onBackButtonClicked: PropTypes.func
};

const InfoCarousel = ({
  carouselJson,
  isNewSignedupUser,
  handleCompleteTutorial,
  startPage,
  createOrg,
  form,
  fetchUserDetails,
  orgId,
  setOrgId
}) => {
  let carousel = React.createRef();
  const [orgName, setOrgName] = useState('');
  const [orgCreated, setOrgCreated] = useState(false);
  const [isNextLoading, setIsNextLoading] = useState(false);

  const { t } = useTranslation();
  const { getFieldDecorator } = form;

  useEffect(() => {
    carousel.goTo(startPage);
  }, [startPage]);

  useEffect(() => {
    if (orgCreated) {
      carousel.next();
    }
  }, [orgCreated]);

  /**
   * if new organization is created, after clicking on create property button
   * user will get redirectted to create property page, not hall of org
   */
  useEffect(() => {
    if (isNewSignedupUser && orgId === '') {
      history.push(URL_CONSTANTS.HALL_OF_ORG());
    }
  }, [carouselJson]);

  const setDisabledBtnVal = () => {
    if (!orgName || Object.values(form.getFieldsError()).some((el) => el !== undefined)) {
      return true;
    }
  };

  const onInputFieldChange = (event) => {
    const targEvent = event.target.value;
    setOrgName(targEvent);
  };

  const onNextClicked = async (i, child) => {
    if (i < carouselJson.children.length - 1) {
      if (child.id === 'Organization' && isNewSignedupUser) {
        setIsNextLoading(true);
        const newOrg = await createOrg({
          name: orgName,
          unit_system: 'IMPERIAL',
          currency: 'USD'
        });
        await fetchUserDetails(false);
        setIsNextLoading(false);
        setOrgCreated(true);
        const newUrl = URL_CONSTANTS.LANDING_ORGANIZATION({ orgId: newOrg.data.id });
        setOrgId(newOrg.data.id);
        history.push(newUrl);
      } else {
        carousel.next();
      }
    } else {
      /**
       * if new organization is created, after clicking on create property button
       * user will get redirectted to create property page, not hall of org
       */
      handleCompleteTutorial();
      if (orgId !== '') {
        history.push({
          pathname: URL_CONSTANTS.CREATE_FARM({ orgId }),
          state: { isFirstProperty: true }
        });
      }
    }
  };

  const onBackButtonClicked = (i) => {
    if (i > 0 && i <= carouselJson.children.length - 1) {
      carousel.prev();
    }
  };

  return (
    <StyledCarousel ref={(node) => (carousel = node)}>
      {carouselJson.children.map((child, i) => (
        <div key={i}>
          <CorouselPageWrapper
            child={child}
            setDisabledBtnVal={setDisabledBtnVal}
            isNewSignedupUser={isNewSignedupUser}
            isNextLoading={isNextLoading}
            i={i}
            carouselJson={carouselJson}
            getFieldDecorator={getFieldDecorator}
            onInputFieldChange={onInputFieldChange}
            t={t}
            onNextClicked={onNextClicked}
            carousel={carousel}
            onBackButtonClicked={onBackButtonClicked}
          />
        </div>
      ))}
    </StyledCarousel>
  );
};

InfoCarousel.propTypes = {
  form: PropTypes.object,
  carouselJson: PropTypes.object,
  startPage: PropTypes.number,
  createOrg: PropTypes.func,
  isNewSignedupUser: PropTypes.bool,
  user: PropTypes.object,
  handleCompleteTutorial: PropTypes.func,
  fetchUserDetails: PropTypes.func,
  orgId: PropTypes.string,
  setOrgId: PropTypes.func
};

InfoCarousel.defaultProps = {
  closeClicked: () => null,
  startPage: 0,
  carouselJson: {}
};

export const InfoCarouselComponent = Form.create({
  name: 'InfoCarousel'
})(InfoCarousel);
