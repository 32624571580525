import cropCycleGif from '../public/crop-cycles.gif';
import allFieldGif from '../public/all-fields.gif';
import timelineGif from '../public/timeline.gif';

let UNPUBLISHED_LANG = [];

if (['local', 'qa', 'staging'].includes(process.env.REACT_APP_envName)) {
  UNPUBLISHED_LANG = [{ value: 'ja', name: 'Japanese', shortLabel: 'JA' }];
}

export const DETECT_FIELD_API_KEY = 'authkey=4605ff51-a417-426d-b119-a22e0365c032';
export const fieldDetectionBaseUrl = 'https://gris.syngentadigitalapps.com/fbm/v1/';

export const AppConstants = {
  TERM_AND_CONDITIONS: '',
  ENVIRONMENT: process.env.REACT_APP_envName,
  BASE_REF_ID: process.env.REACT_APP_BASE_REF_ID,
  WHATS_NEW_OFF_FOR_NEW_USER: 24, // in hours
  SIGNUP_TIMER: 30, // in minutes
  DATE_FORMATS: {
    YYYY_DASH_MM_DASH_DD_WITH_TIME: `yyyy-MM-dd'T'HH:mm:ss'Z'`,
    MMM_SPACE_YEAR: `MMM yyyy`,
    YYYY_DASH_MM_DASH_DD: 'YYYY-MM-DD',
    YYYY_DASH_MM_DASH_DDTHH_COLON_mm_COLON_ss: 'YYYY-MM-DDTHH:mm:ss',
    dddd_SPACE_DD_SPACE_MMM: 'dddd DD MMM'
  },
  SUPPORTED_LANGS: [
    { value: 'en', name: 'English (USA)', shortLabel: 'EN' },
    { value: 'pt-BR', name: 'Portuguese', shortLabel: 'PT' },
    { value: 'fr', name: 'French', shortLabel: 'FR' },
    { value: 'de', name: 'German', shortLabel: 'DE' },
    { value: 'pl', name: 'Polski', shortLabel: 'PL' },
    { value: 'es', name: 'Spanish', shortLabel: 'ES' },
    { value: 'es-419', name: 'Spanish (Latin America)', shortLabel: 'ES-419' },
    { value: 'bg', name: 'Bulgarian', shortLabel: 'BG' },
    { value: 'hu', name: 'Hungarian', shortLabel: 'HU' },
    { value: 'ro', name: 'Romanian', shortLabel: 'RO' },
    { value: 'el', name: 'Greek', shortLabel: 'EL' },
    { value: 'nl', name: 'Dutch', shortLabel: 'NL' },
    { value: 'da', name: 'Danish', shortLabel: 'DA' },
    { value: 'sv', name: 'Swedish', shortLabel: 'SV' },
    { value: 'uk', name: 'Ukrainian', shortLabel: 'UK' },
    { value: 'it', name: 'Italian', shortLabel: 'IT' },
    ...UNPUBLISHED_LANG
  ],
  VALIDATIONS: {
    MOBILE_DIGITS_LENGTH: 20,
    MIN_MOBILE_DIGITS_LENGTH: 8,
    NAME_MAX_LENGTH: 50,
    COMMENTS_MAX_LENGTH: 100,
    ZIP_CODE_MAX_LENGTH: 15,
    CITY_MAX_LENGTH: 50,
    COUNTRY_MAX_LENGTH: 25,
    STATE_MAX_LENGTH: 50,
    FIELD_NAME_MAX_LENGTH: 100,
    EIN_MAX_LENGTH: 20,
    ADDRESS_MAX_LENGTH: 100,
    SEASONS_NAME_LENGTH: 50,
    MAX_LENGTH: 255
  },
  EMAIL_MAX_LENGTH: 352,
  PASSWORD_MAX_LENGTH: 50,
  VOUCHER_CODE_LENGTH: 19,
  ADD_FIELDS: {
    SEARCH_NAME_MAX_LENGTH: 25
  },
  DATE_PERIOD: {
    DATE_FORMAT: 'MMM DD, YYYY',
    NINE: 9,
    ONE: 1,
    SIX: 6,
    THREE_SIXTY_FIVE: 365,
    THREE_ZERO: 30,
    YEARS: 'years',
    MONTHS: 'months',
    DAYS: 'days'
  },
  CLOCK_FORMAT: {
    FORMAT_12: 'FORMAT_12',
    FORMAT_24: 'FORMAT_24'
  },
  DATE_FORMATS_SETTINGS: ['DD-MM-YYYY', 'DD-MM-YY', 'YYYY-MM-DD', 'MM-DD-YYYY', 'DD-MMM-YYYY'],
  getTimeConstants: (t) => ({
    [AppConstants.CLOCK_FORMAT.FORMAT_12]: [
      '12:00 PM',
      '03:00 PM',
      '06:00 PM',
      '09:00 PM',
      '12:00 AM',
      '03:00 AM',
      '06:00 AM',
      '09:00 AM'
    ],
    [AppConstants.CLOCK_FORMAT.FORMAT_24]: [
      t('MIDDAY'),
      '15:00',
      '18:00',
      '21:00',
      t('MIDNIGHT'),
      '03:00',
      '06:00',
      '09:00'
    ]
  }),
  TASK_STATUS: (status, primaryStatus, t) => {
    const mapping = {
      PENDING: { text: t('Pending'), color: '#707374' },
      DELAYED: { text: t('Delayed'), color: '#F74141' }
    };
    return mapping[status] || primaryStatus;
  },
  TASK_DEFAULT_STATUS_COLOR: (status) =>
    ({
      Planned: '#707374',
      'In progress': '#EB8205',
      Complete: '#2FB966'
    }[status]),
  GRAPH_DEFAULT_COLORS: ['#EB8205', '#F9C43D', '#00A0BE'],
  O_AUTH: {
    responseCode: 'code',
    clientId: 'strix-ui'
  },
  PROPERTY_WRITE_PERMISSIONS: [
    {
      scope: 'WRITE',
      name: 'TASKS'
    },
    {
      scope: 'WRITE',
      name: 'WAREHOUSES'
    },
    {
      scope: 'WRITE',
      name: 'FIELDS'
    },
    {
      scope: 'WRITE',
      name: 'INFORMATION'
    }
  ],
  PROPERTY_READ_PERMISSIONS: [
    {
      scope: 'READ',
      name: 'TASKS'
    },
    {
      scope: 'READ',
      name: 'WAREHOUSES'
    },
    {
      scope: 'READ',
      name: 'FIELDS'
    }
  ],
  DEFAULT_PERMISSIONS: {
    permissions: [
      {
        scope: 'READ',
        name: 'ORG'
      },
      {
        scope: 'READ',
        name: 'ASSIGNEES'
      },
      {
        scope: 'WRITE',
        name: 'ASSIGNEES'
      },
      {
        scope: 'READ',
        name: 'FARMSHOTS'
      },
      {
        scope: 'READ',
        name: 'VENDORS'
      },
      {
        scope: 'READ',
        name: 'PROPERTIES'
      },
      /* {
        scope: 'WRITE',
        name: 'PROPERTIES'
      }, */
      {
        scope: 'READ',
        name: 'EQUIPMENTS'
      },
      {
        scope: 'WRITE',
        name: 'EQUIPMENTS'
      },
      {
        scope: 'READ',
        name: 'PRODUCTS'
      },
      {
        scope: 'WRITE',
        name: 'PRODUCTS'
      },
      {
        scope: 'READ',
        name: 'SEASONS'
      },
      {
        scope: 'READ',
        name: 'SUPPLIES'
      },
      {
        scope: 'WRITE',
        name: 'SUPPLIES'
      },
      {
        scope: 'READ',
        name: 'INVENTORY'
      },
      {
        scope: 'WRITE',
        name: 'INVENTORY'
      },
      {
        scope: 'READ',
        name: 'FINANCIAL'
      },
      {
        scope: 'READ',
        name: 'SEASON_AREA'
      },
      {
        scope: 'WRITE',
        name: 'SEASON_AREA'
      },
      {
        scope: 'READ',
        name: 'INVENTORY'
      },
      {
        scope: 'WRITE',
        name: 'INVENTORY'
      },
      {
        scope: 'READ',
        name: 'FINANCIAL'
      }
    ]
  },
  OWNER_WRITE_PERMISSION: {
    scope: 'WRITE',
    name: 'OWNER'
  },
  OWNER_READ_PERMISSION: {
    scope: 'READ',
    name: 'OWNER'
  },
  WRITE_PERMISSION: {
    permissions: [
      {
        scope: 'READ',
        name: 'ORG'
      },
      {
        scope: 'WRITE',
        name: 'ORG'
      },
      {
        scope: 'READ',
        name: 'ASSIGNEES'
      },
      {
        scope: 'WRITE',
        name: 'ASSIGNEES'
      },
      {
        scope: 'READ',
        name: 'FARMSHOTS'
      },
      {
        scope: 'READ',
        name: 'VENDORS'
      },
      {
        scope: 'WRITE',
        name: 'VENDORS'
      },
      {
        scope: 'READ',
        name: 'PROPERTIES'
      },
      /* {
        scope: 'WRITE',
        name: 'PROPERTIES'
      }, */
      {
        scope: 'READ',
        name: 'EQUIPMENTS'
      },
      {
        scope: 'WRITE',
        name: 'EQUIPMENTS'
      },
      {
        scope: 'READ',
        name: 'PRODUCTS'
      },
      {
        scope: 'WRITE',
        name: 'PRODUCTS'
      },
      {
        scope: 'READ',
        name: 'SEASONS'
      },
      {
        scope: 'WRITE',
        name: 'SEASONS'
      },
      {
        scope: 'READ',
        name: 'SUPPLIES'
      },
      {
        scope: 'WRITE',
        name: 'SUPPLIES'
      },
      {
        scope: 'READ',
        name: 'USERS'
      },
      {
        scope: 'WRITE',
        name: 'USERS'
      },
      {
        scope: 'READ',
        name: 'OWNER'
      },
      {
        scope: 'WRITE',
        name: 'OWNER'
      },
      {
        scope: 'READ',
        name: 'INVENTORY'
      },
      {
        scope: 'WRITE',
        name: 'INVENTORY'
      },
      {
        scope: 'READ',
        name: 'FINANCIAL'
      }
    ]
  },
  WRITE_REMOVE_FOR_READ_PERMISSION: {
    permissions: [
      {
        scope: 'WRITE',
        name: 'ORG'
      },
      {
        scope: 'WRITE',
        name: 'VENDORS'
      },
      {
        scope: 'WRITE',
        name: 'SEASONS'
      },
      {
        scope: 'WRITE',
        name: 'USERS'
      },
      {
        scope: 'WRITE',
        name: 'OWNER'
      }
    ]
  },
  WRITE_REMOVE_FOR_VIEW_PERMISSION: {
    permissions: [
      {
        scope: 'READ',
        name: 'USERS'
      },
      {
        scope: 'READ',
        name: 'OWNER'
      },
      {
        scope: 'WRITE',
        name: 'VENDORS'
      },
      {
        scope: 'WRITE',
        name: 'SEASONS'
      },
      {
        scope: 'WRITE',
        name: 'USERS'
      },
      {
        scope: 'WRITE',
        name: 'OWNER'
      }
    ]
  },
  WRITE_REMOVE_FOR_DEFAULT_PERMISSION: {
    permissions: [
      {
        scope: 'WRITE',
        name: 'VENDORS'
      },
      {
        scope: 'WRITE',
        name: 'SEASONS'
      },
      {
        scope: 'WRITE',
        name: 'USERS'
      },
      {
        scope: 'WRITE',
        name: 'OWNER'
      },
      {
        scope: 'READ',
        name: 'INVENTORY'
      },
      {
        scope: 'WRITE',
        name: 'INVENTORY'
      },
      {
        scope: 'READ',
        name: 'FINANCIAL'
      }
    ]
  },
  READ_PERMISSION: {
    permissions: [
      {
        scope: 'READ',
        name: 'ORG'
      },
      {
        scope: 'READ',
        name: 'ASSIGNEES'
      },
      {
        scope: 'WRITE',
        name: 'ASSIGNEES'
      },
      {
        scope: 'READ',
        name: 'FARMSHOTS'
      },
      {
        scope: 'READ',
        name: 'VENDORS'
      },
      {
        scope: 'READ',
        name: 'PROPERTIES'
      },
      /* {
        scope: 'WRITE',
        name: 'PROPERTIES'
      }, */
      {
        scope: 'READ',
        name: 'EQUIPMENTS'
      },
      {
        scope: 'WRITE',
        name: 'EQUIPMENTS'
      },
      {
        scope: 'READ',
        name: 'PRODUCTS'
      },
      {
        scope: 'WRITE',
        name: 'PRODUCTS'
      },
      {
        scope: 'READ',
        name: 'SEASONS'
      },
      {
        scope: 'READ',
        name: 'SUPPLIES'
      },
      {
        scope: 'WRITE',
        name: 'SUPPLIES'
      },
      {
        scope: 'READ',
        name: 'USERS'
      },
      {
        scope: 'READ',
        name: 'OWNER'
      },
      {
        scope: 'READ',
        name: 'INVENTORY'
      },
      {
        scope: 'WRITE',
        name: 'INVENTORY'
      },
      {
        scope: 'READ',
        name: 'FINANCIAL'
      }
    ]
  },
  MAX_PAGE_SIZE_FETCH_ORGS: 20,
  FIELD_VERSIONING_TYPE: {
    PREVIOUS_VERSION: 'PREVIOUS_VERSION',
    NEW_VERSION: 'NEW_VERSION'
  },
  POPUP_DETAILS_COMPONENT_MODEL_STRUCTURE: {
    ALL_FIELDS: {
      title: 'Fields',
      gifFile: allFieldGif,
      description:
        'Fields are the smallest parts of your digital farm. You can add new fields and edit the borders of created fields.'
    },
    TIMELINE: {
      title: 'Timeline',
      gifFile: timelineGif,
      description:
        'Timeline shows main information about your fields. Here you find recent changes and associated crops.'
    },
    CROP_CYCLES: {
      title: 'Crop cycles',
      gifFile: cropCycleGif,
      description:
        // eslint-disable-next-line no-irregular-whitespace
        'Crop cycle is a group of fields that share the same crop for a period. Here you can set your crop cycles’ agronomic data.'
    }
  },
  FIX_TOOL_SETTINGS: {
    buffer_zone: 3,
    max_cut_out: 0.01
  },
  PAGE_PATHS: {
    LANDING_ORG: 'landing-organization',
    NO_SIDEBAR_FOR: ['/app/no-access'],
    PROPERTY_FLOW_PAGES: ['property', 'edit-farm'],
    FIELDS_PAGES: [
      'create-fields',
      'all-fields',
      'edit-field',
      'create-region',
      'edit-region',
      'all-regions',
      'draw-field',
      'create-field',
      'manage-regions'
    ],
    CROP_CYCLE_PAGES: ['table-view', 'map-view'],
    INDEPENDANT_PAGES: ['settings', 'my-organizations'],
    TIMELINE_PAGES: ['timeline']
  },
  FIELD_DETECTION_SOURCE_LIST_URL: `${fieldDetectionBaseUrl}providers?${DETECT_FIELD_API_KEY}`,
  FIELD_DETECTION_DEFAULT_PROVIDER: 'Common Land Unit (CLU)',
  FIELD_DETECTION_GET_BOUNDRIES: `${fieldDetectionBaseUrl}boundaries?${DETECT_FIELD_API_KEY}`
};
