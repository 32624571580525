const getDetailsFromIPAPI = async () => {
  const details = await sessionStorage.getItem('ipapi-details');
  if (details) {
    return JSON.parse(details);
  }
  return {};
};

export const getLocation = () => {
  const details = sessionStorage.getItem('ipapi-details');
  if (details) {
    const result = JSON.parse(details);
    return {
      latitude: result.latitude,
      longitude: result.longitude,
      country_code: result.country_code && result.country_code.toLowerCase()
    }
  }
  return {};
};

export const getLocationFromBrowser = async() => {
  try {
    const result = await getDetailsFromIPAPI();
    return {
      latitude: result.latitude,
      longitude: result.longitude,
      country_code: result.country_code && result.country_code.toLowerCase()
    }
  } catch(e) {
    return {};
  }
};

interface IGoogleLibraryAPI {
  maps: {
    Geocoder: any;
  };
}

const getGoogleLibrary = (): IGoogleLibraryAPI => (window as any).google;
export const isGoogleLibraryLoaded = () => !!getGoogleLibrary();

export const getCountryCode = async() => {
  try {
    const result = await getDetailsFromIPAPI();
    return result.country_code && result.country_code.toLowerCase();
  } catch(e) {
    return 'us';
  }
};
