import {
  FETCH_FIELDS_BY_SEASONS_PROP_ID_SUCCESS,
  FETCH_FIELDS_BY_SEASONS_PROP_ID_LOADING,
  FETCH_ALL_FIELDS_BY_PROP_ID_LOADING,
  FETCH_ALL_FIELDS_BY_PROP_ID_SUCCESS,
  FETCH_ALL_FIELDS_BY_PROP_ID_ERROR,
  FETCH_ALL_FIELDS_HISTORY_BY_PROP_ID_LOADING,
  FETCH_ALL_FIELDS_HISTORY_BY_PROP_ID_SUCCESS,
  FETCH_ALL_FIELDS_HISTORY_BY_PROP_ID_ERROR,
  FETCH_FIELD_HISTORY_DETAILS_BY_ID_LOADING,
  FETCH_FIELD_HISTORY_DETAILS_BY_ID_SUCCESS,
  FETCH_FIELD_HISTORY_DETAILS_BY_ID_ERROR
} from '../actions/types';

const initialState = {
  loading: false,
  error: null,
  fieldsBySeasonsPropId: [],
  allFieldsByPropId: [],
  allFieldsByPropIdLoading: false,
  allFieldsByPropIdError: null,
  allNormalizedFieldsByPropId: {
    entities: {},
    result: []
  },
  allFieldsHistoryByPropId: [],
  allFieldsHistoryByPropIdLoading: false,
  allFieldsHistoryByPropIdError: null,
  fieldHistoryById: {},
  fieldHistoryByIdLoading: false,
  fieldHistoryByIdError: null
};

export const fields = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FIELDS_BY_SEASONS_PROP_ID_LOADING:
      return {
        ...state,
        loading: true
      };
    case FETCH_FIELDS_BY_SEASONS_PROP_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        fieldsBySeasonsPropId: action.payload
      };

    case FETCH_ALL_FIELDS_BY_PROP_ID_LOADING:
      return {
        ...state,
        allFieldsByPropIdLoading: true
      };

    case FETCH_ALL_FIELDS_BY_PROP_ID_ERROR:
      return {
        ...state,
        allFieldsByPropIdLoading: false,
        allFieldsByPropIdError: action.err
      };
    case FETCH_ALL_FIELDS_BY_PROP_ID_SUCCESS:
      return {
        ...state,
        allFieldsByPropIdLoading: false,
        allFieldsByPropId: action.payload,
        allNormalizedFieldsByPropId: action.normalizedPayload
      };

    case FETCH_ALL_FIELDS_HISTORY_BY_PROP_ID_LOADING:
      return {
        ...state,
        allFieldsHistoryByPropIdLoading: true,
        allFieldsHistoryByPropIdError: null,
        allFieldsHistoryByPropId: []
      };
    case FETCH_ALL_FIELDS_HISTORY_BY_PROP_ID_ERROR:
      return {
        ...state,
        allFieldsHistoryByPropIdLoading: false,
        allFieldsHistoryByPropIdError: action.err,
        allFieldsHistoryByPropId: []
      };
    case FETCH_ALL_FIELDS_HISTORY_BY_PROP_ID_SUCCESS:
      return {
        ...state,
        allFieldsHistoryByPropIdLoading: false,
        allFieldsHistoryByPropIdError: null,
        allFieldsHistoryByPropId: action.payload
      };

    case FETCH_FIELD_HISTORY_DETAILS_BY_ID_LOADING:
      return {
        ...state,
        fieldHistoryByIdLoading: true,
        fieldHistoryByIdError: null,
        fieldHistoryById: {}
      };
    case FETCH_FIELD_HISTORY_DETAILS_BY_ID_ERROR:
      return {
        ...state,
        fieldHistoryByIdLoading: false,
        fieldHistoryByIdError: action.err,
        fieldHistoryById: {}
      };
    case FETCH_FIELD_HISTORY_DETAILS_BY_ID_SUCCESS:
      return {
        ...state,
        fieldHistoryByIdLoading: false,
        fieldHistoryByIdError: null,
        fieldHistoryById: action.payload
      };

    default:
      return state;
  }
};
