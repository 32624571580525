import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';

const Themes = {
  defaultTheme: {
    colors: {
      titleColor: '#363948',
      inputBackground: '#F3F4F6',
      inputBorder: '#E8EAED',
      pageBackgroundSecondary: '#E5E5E5',
      pageBackgroundPrimary: '#FFFFFF',
      primary: '#00B5D8',
      secondary: '#FFFFFF',
      btnGreen: '#AAB400',
      fontColor: '#00B5D8',
      inputFontColor: '#363948',
      fontSecondaryColor: '#14151C',
      focusRectangleColor: '#aab400',
      ErrorColor: 'red'
    },
    font: {
      family: 'Rubik, sans-serif'
    },
    fontSizes: {
      small: '12px',
      medium: '14px',
      large: '20px',
      Xlarge: '27.7941px'
    }
  },
  newTheme: {
    colors: {},
    fonts: [],
    fontSizes: {}
  }
};

export const Theme = ({ themeName, children }) => (
  <ThemeProvider theme={Themes[themeName]}>{children}</ThemeProvider>
);

Theme.propTypes = {
  themeName: PropTypes.string.isRequired
};

export const toThemeHoc = (Component, themeName) => (props) => (
  <Theme themeName={themeName}>
    <Component {...props} />
  </Theme>
);
