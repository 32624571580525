import {
  TOGGLE_HEADER,
  SET_SELECTED_ORG,
  SET_SELECTED_PROPERTY,
  SET_SELECTED_SEASONS,
  TOGGLE_DEEP_ACTION,
  SET_WHATS_NEW_ISVISIBLE,
  HAVE_CONTRACT_FOR_ORG
} from '../actions/types';

const initialState = {
  collapsed: false,
  selectedOrganization: null,
  selectedProperty: null,
  selectedSeasons: null,
  disabledComponents: false,
  isWhatsNewVisible: false,
  haveContractForOrg: false
};

export const header = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_HEADER:
      return {
        ...state,
        collapsed: (action.payload !== null && action.payload) || !state.collapsed
      };

    case TOGGLE_DEEP_ACTION:
      return {
        ...state,
        disabledComponents: action.payload !== null && action.payload
      };

    case SET_SELECTED_ORG:
      return {
        ...state,
        selectedOrganization: action.payload
      };

    case SET_SELECTED_PROPERTY:
      return {
        ...state,
        selectedProperty: action.payload
      };

    case SET_SELECTED_SEASONS:
      const selectedSeasons = action.payload || [];
      return {
        ...state,
        selectedSeasons
      };
    case SET_WHATS_NEW_ISVISIBLE:
      return {
        ...state,
        isWhatsNewVisible: action.payload
      };
    case HAVE_CONTRACT_FOR_ORG:
      return {
        ...state,
        haveContractForOrg: action.payload
      };
    default:
      return state;
  }
};
