/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { INavigationRouteProps } from '../../core/navigation/models';
import { StyledModal } from 'components/InfoCarouselModal/InfoCarousel.style';
import { InfoCarouselComponent } from 'components/InfoCarouselModal/InfoCarousel';
import { updateProfileSettings } from '../../actions/profileSettings';
import { fetchUserProfile } from '../../actions/user';
import { useTranslation } from 'react-i18next';

interface IDeckOfAppsModal extends INavigationRouteProps<{}> {
  user: any;
  updateProfileSettings: any;
}

const DeckOfAppsModal: React.FC<IDeckOfAppsModal> = (props) => {
  const { t } = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);
  const handleCompleteTutorial = () => {
    const userDetails = { ...props.user, opt_ins: undefined };
    userDetails.json_extended_attributes = {
      ...userDetails.json_extended_attributes,
      deck_tutorial_complete: true
    };
    props.updateProfileSettings(userDetails, props.user.id);
    const deckOfAppsIcon = (document.getElementsByClassName('cw-deck-of-apps-icon')[0] as HTMLElement)
    if(deckOfAppsIcon){
      deckOfAppsIcon.click();
    }
    setModalVisible(false);
  };

  const carouselJson = {
    btnText: t('Close'),
    lastBtnText: t('Close'),
    onBtnClicked: () => null,
    children: [
      {
        image: 1,
        id: 'Products',
        title: t('Welcome to Base!'),
        description: t(
          'Here at Base you register your organizations, properties and fields. To access your other Cropwise Products, use the navigation menu.'
        )
      }
    ]
  };

  useEffect(() => {
    if (get(props.user, 'tutorial_complete') !== undefined) {
      let decktutorialcomplete = true;
      if (get(props.user, 'tutorial_complete')) {
        decktutorialcomplete = get(props.user.json_extended_attributes, 'deck_tutorial_complete');
      }
      const isDeepAcction = window.location.href.includes('deep-actions');
      const shouldModalBeVisible = decktutorialcomplete || isDeepAcction
      setModalVisible(!shouldModalBeVisible);
    }
  }, [props.user]);

  return (
    <StyledModal
      centered
      width={592}
      footer={null}
      visible={modalVisible}
      closeIcon={() => handleCompleteTutorial()}
      maskClosable={false}>
      <InfoCarouselComponent
        {...props}
        carouselJson={carouselJson}
        handleCompleteTutorial={handleCompleteTutorial}
      />
    </StyledModal>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.userProfile
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  updateProfileSettings: (userDetails: any, userId: string) =>
    dispatch(updateProfileSettings(userDetails, userId, true)),
  fetchUserDetails: () => dispatch(fetchUserProfile())
});

export const DeckOfAppsModalComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(DeckOfAppsModal);
