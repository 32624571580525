import { Carousel, Modal, Button } from 'antd';
import styled from 'styled-components';
import WelcomeImage1 from '../../public/welcome-summary-1.svg';
import WelcomeImage2 from '../../public/welcome-summary-2.svg';
import WelcomeImage3 from '../../public/welcome-summary-3.svg';
import WelcomeImage4 from '../../public/welcome-summary-4.svg';
import WelcomeImage5 from '../../public/welcome-summary-5.svg';
import WelcomeImage6 from '../../public/welcome-summary-6.svg';
import WelcomeImage7 from '../../public/createOrgCarouselImage.svg';
import { iff } from '../../utils/iff';

const welcomeImages = [
  WelcomeImage1,
  WelcomeImage2,
  WelcomeImage3,
  WelcomeImage4,
  WelcomeImage5,
  WelcomeImage6,
  WelcomeImage7
];
export const StyledCarousel = styled(Carousel)`
  .slick-slide {
    min-height: 445px;
    background: #ffffff;
    overflow: hidden;
  }

  .slick-dots li button {
    width: 8px;
    height: 8px;
    border-radius: 8px;
    background: gray;
  }
  .slick-dots li.slick-active button {
    background: #00004b;
    width: 8px;
  }
  .slick-dots li.slick-active {
    width: 16px;
  }
  .slick-dots li {
    margin: 0px;
    margin-right: 0px;
    margin-left: 0px;
  }
  .slick-dots-bottom {
    bottom: 40%;
  }
  &.property-setup-popup {
    .property-steps-container {
      padding-left: 0;
      margin: 0;
      counter-reset: item;
      list-style-type: none;
      .property-steps {
        display: block;
        &:before {
          content: counter(item) ' - ';
          counter-increment: item;
        }
      }
    }
    .button-container {
      top: -15px;
      button {
        font-family: Noto Sans, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.0075em;
      }
    }
  }
`;

export const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 0;
  }
  .slick-list {
    border-radius: 8px;
  }
  z-index: ${(props) => iff(props.zIndex, props.zIndex, 'initial')};
`;

export const StyledPage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 410px;
  .create-org-carousel-form .has-error .ant-legacy-form-explain {
    margin-bottom: 10px;
  }
`;
export const StyledWelcomeImage = styled.div`
  background-image: url(${(props) => welcomeImages[props.image]});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 240px;
  width: 100%;
  border-bottom: 2px solid #14803c;
`;
export const StyledImageContainer = styled.div`
    height: 240px;
    width: 100%;
    background-color: rgb(243, 244, 246);
    border-bottom: 2px solid #14803c;
    display: flex;
    align-items: center;
}
`;
export const StyledImage = styled.div`
  background-image: url(${(props) => props.image});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 240px;
  width: 100%;
  border-bottom: 2px solid #14803c;
`;
export const StyledTitle = styled.h3`
  font-family: Noto Sans, Rubik, sans-serif;
  align-self: flex-start;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #14151c;
  margin: 10px 32px 20px 32px;
`;
export const StyledDescription = styled.div`
  font-family: Noto Sans, Rubik, sans-serif;
  align-self: flex-start;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #14151c;
  margin: 0 32px;
  position: relative;
  top: -20px;
`;
export const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 32px;
  flex-direction: row-reverse;
  position: relative;
  top: 5px;
`;
export const StyledButtonNext = styled(Button)`
  cursor: pointer;
  height: 40px;
  font-family: Noto Sans, Rubik, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  background: #14803c;
  color: #ffffff;
  &:disabled {
    background: #f3f4f6;
    border: 1px solid #dfe2e7;
    color: #c2c7d0;
  }
  &:hover {
    background: #14803c;
    color: #ffffff;
    border-color: #14803c;
  }
  .create-org-carousel &.next {
    max-width: 65px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    span {
      display: inline;
    }
  }
`;
export const StyledButtonBack = styled(Button)`
  cursor: pointer;
  height: 40px;
  font-family: Noto Sans, Rubik, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #14803c;
  background: #ffffff;
  border-color: #14803c;
  box-shadow: none;
  margin: 0px 12px;
  &:hover {
    color: #14803c;
    background: #ffffff;
    border-color: #14803c;
  }
`;

export const StyledModalWhatsNew = styled(Modal)`
  z-index: ${(props) => iff(props.zIndex, props.zIndex, 'initial')};
  width: 700px !important;
  margin: 80px 0px;
  padding-bottom: 0px;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  .ant-modal-content {
    padding-bottom: 15px;
  }
  .ant-modal-body {
    padding: 32px 40px 0px 32px;
    overflow: auto;
    height: calc(100vh - 250px);
    margin-right: 4px;
  }
  .slick-list {
    border-radius: 8px;
  }
  .ant-modal-header {
    color: #ffffff;
    background: linear-gradient(95.48deg, #00004b 29.47%, #369f61 98.82%);
    height: 72px;
    padding: 22px 30px 22px 25px;
  }
  .ant-modal-content {
    border-radius: 8px;
  }
  .ant-modal-content .ant-modal-header {
    border-radius: 8px 8px 0px 0px;
  }
  .title-text {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.0075em;
    color: #ffffff;
    display: inline-block;
    margin-left: 17px;
  }
  .ant-modal-title {
    img {
      margin-bottom: 6px;
    }
  }
  .ant-modal-close {
    top: 7px;
    right: 6px;
    color: #ffffff;
  }
  .ant-modal-footer {
    background: #ffffff;
    box-shadow: inset 0px 1px 0px #c2c7d0;
    padding: 16px 40px;
    .footer-text {
      color: #14803c;
      font-family: Noto Sans, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      cursor: pointer;
    }
  }
  .modal-content {
    font-family: Noto Sans, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: -0.0075em;
    color: #4d5165;
    .item-title {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #14151c;
      margin-bottom: 4px;
    }
    .item-date {
      color: #868ca2;
      margin-bottom: 15px;
    }
    .item-image {
      width: 100%;
      height: 100%;
      margin-bottom: 15px;
    }
  }
  .squareicon {
    display: inline-block;
    width: 32px;
    height: 32px;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
    margin: 0px 8px;
    cursor: pointer;
  }
  .ant-modal-body::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    cursor: pointer;
    margin-right: 4px;
  }
  .ant-modal-body::-webkit-scrollbar-thumb {
    cursor: pointer;
    border-radius: 8px;
    box-shadow: inset 0 3px 2px 6px #c2c7d0;
    -webkit-box-shadow: inset 0 3px 2px 6px #c2c7d0;
  }
  .ant-modal-body::-webkit-scrollbar-track {
    cursor: pointer;
    border-radius: 8px;
    background: none;
    border-top: 2px solid white;
    border-bottom: 2px solid white;
    border-right: 2px solid white;
    border-left: 2px solid white;
  }
  .deck-of-app-icon {
    height: 32px;
    width: 32px;
    border-radius: 4px;
    background: #ffffff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    margin-top: -50px;
  }
  .line-divider {
    width: 100%;
    height: 1px;
    background: #dfe2e7;
    margin-top: 23px;
    margin-bottom: 23px;
  }
  img[alt='Square Icon'] {
    width: 32px;
    height: 32px;
  }
  #field-detection-tool,
  #menu {
    color: rgb(0, 113, 205);
  }
`;
