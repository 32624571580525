import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { notification } from 'antd';
import { getLocationFromBrowser } from './location';
import { getPropertyDetailsById } from '../actions';

export const getLatLongByPropId = async (propertyId, setLat, setLong, DEFAULT_LAT_LONG) => {
  const propertyRes = await getPropertyDetailsById(propertyId);
  const coordinates = get(propertyRes.data, 'reference_point.coordinates');
  if (isEmpty(coordinates)) {
    (async () => {
      try {
        const location = await getLocationFromBrowser();
        setLong(location.longitude);
        setLat(location.latitude);
      } catch (err) {
        notification.error({
          message: 'Error',
          description: err.message
        });
      }
    })();
  } else {
    setLat(coordinates[1] || DEFAULT_LAT_LONG);
    setLong(coordinates[0] || DEFAULT_LAT_LONG);
  }
  return propertyRes;
};
