import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

const PRIVATE_ROUTE_PREFIX_BACKWARD = 'app/';
export const LANDING_ORG_ROUTE = `${PRIVATE_ROUTE_PREFIX_BACKWARD}landing-organization`;
export const FARM_ROUTE = 'property';
export const SEASON_ROUTE = 'season';
const PRIVATE_ROUTE_PREFIX_FORWARD = '/app';

export const URL_CONSTANTS = {
  ROOT: () => '/',
  LOGIN: () => '/login',
  SIGNUP_1: () => '/sign-up-1',
  SIGNUP_2: () => '/sign-up-2',
  RECOVERY_PASSWORD: () => '/recovery-password',
  RESET_PASSWORD: () => '/reset-password',
  REVIEW_PAGE: () => '/app/review-page',
  NO_ACCESS: () => '/app/no-access',
  HALL_OF_ORG: () => `${PRIVATE_ROUTE_PREFIX_FORWARD}/my-organizations`,
  TERMS_AND_CONDITIONS: () => '/terms-and-conditions',
  SETTINGS: () => `${PRIVATE_ROUTE_PREFIX_FORWARD}/settings`,
  MYSERVICES: ({ orgId }) => `${PRIVATE_ROUTE_PREFIX_FORWARD}/${orgId}/my-services`,
  RESOURCES: ({ orgId, type }) => {
    const typePath = `/${type}`;
    return `/${LANDING_ORG_ROUTE}/${orgId}/resources${type ? typePath : ''}`;
  },
  LANDING_ORGANIZATION: ({ orgId }) => `/${LANDING_ORG_ROUTE}/${orgId}`,
  CREATE_FARM: ({ orgId }) => `/${LANDING_ORG_ROUTE}/${orgId}/create-farm`,
  EDIT_FARM: ({ orgId, farmId }) => `/${LANDING_ORG_ROUTE}/${orgId}/edit-farm/${farmId}`,
  LANDING_FARM: ({ orgId, farmId }) => `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}`,

  ALL_FIELDS: ({ orgId, farmId }) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/all-fields`,
  CREATE_TASK: ({ orgId, farmId }) => `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/task`,
  SELECT_TASK_TYPE: ({ orgId, farmId }) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/select-task-types`,
  EDIT_TASK: ({ orgId, farmId, taskId }) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/task/${taskId}`,
  CREATE_FIELDS: ({ orgId, farmId }) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/create-fields`,
  CREATE_FIELD_NO_BOUNDARY: ({ orgId, farmId }) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/create-field/no-boundary`,
  CREATE_FIELD_SHAPE_FILE_UPLOADER: ({ orgId, farmId }) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/create-field/uploader`,
  CREATE_FIELD_SHAPE_FILE_LIST: ({ orgId, farmId }) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/create-field/uploaded-list`,
  CREATE_FIELD_DRAW: ({ orgId, farmId }) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/create-field/draw-field`,
  CREATE_DETECT_FIELD: ({ orgId, farmId }) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/create-field/detect-fields`,
  EDIT_FIELD_DRAW: ({ orgId, farmId, fieldId }) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/edit-field/${fieldId}`,
  EDIT_FIELD_DRAW_UPLOADED: ({ orgId, farmId }) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/edit-field/upload`,
  EDIT_FIELD_DRAW_DETECT_FIELDS: ({ orgId, farmId }) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/edit-field/detect-fields`,
  EDIT_SUB_REGION_FIELD_DRAW_DETECT_FIELDS: ({ orgId, farmId, parentRegionId }) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/region/${parentRegionId}/edit-field/detect-fields`,
  CREATE_SUB_REGION_FIELD_SHAPE_FILE_UPLOADER: ({ orgId, farmId, parentRegionId }) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/region/${parentRegionId}/create-field/uploader`,
  CREATE_SUB_REGION_FIELD_SHAPE_FILE_LIST: ({ orgId, farmId, parentRegionId }) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/region/${parentRegionId}/create-field/uploaded-list`,
  EDIT_SUB_REGION_FIELD_DRAW_UPLOADED: ({ orgId, farmId, parentRegionId }) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/region/${parentRegionId}/edit-field/upload`,
  EDIT_SUB_REGION_FIELD_DRAW: ({ orgId, farmId, parentRegionId, fieldId }) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/region/${parentRegionId}/edit-field/${fieldId}`,
  CREATE_SUB_REGION_FIELD_DRAW: ({ orgId, farmId, parentRegionId }) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/region/${parentRegionId}/create-field/draw-field`,
  CREATE_SUB_REGION_DETECT_FIELD: ({ orgId, farmId, parentRegionId }) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/region/${parentRegionId}/create-field/detect-fields`,

  CREATE_SEASON: ({ orgId }) => `/${LANDING_ORG_ROUTE}/${orgId}/${SEASON_ROUTE}`,
  EDIT_SEASON: ({ orgId, seasonId }) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${SEASON_ROUTE}/${seasonId}`,
  RECOVERY_EMAIL_SENT: () => '/recovery-email-sent',
  ASSOCIATE_NEW_CROP: ({ orgId, farmId }) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/associate-new-crop`,
  LANDING_PROPERTY_TABLE_VIEW: ({ orgId, farmId }) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/table-view`,
  LANDING_PROPERTY_MAP_VIEW: ({ orgId, farmId }) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/map-view`,
  UPLOAD_FILE_DEEP_ACTION: ({ callbackUri, orgId, farmId }) =>
    `${PRIVATE_ROUTE_PREFIX_FORWARD}/deep-actions/upload-file?org_id=${orgId}&property_id=${farmId}&callback_uri=${callbackUri}`,
  DRAW_FIELD_DEEP_ACTION: ({ callbackUri, orgId, farmId }) =>
    `${PRIVATE_ROUTE_PREFIX_FORWARD}/deep-actions/draw-field?org_id=${orgId}&property_id=${farmId}&callback_uri=${callbackUri}`,
  DETECT_FIELD_DEEP_ACTION: ({ callbackUri, orgId, farmId }) =>
    `${PRIVATE_ROUTE_PREFIX_FORWARD}/deep-actions/detect-field?org_id=${orgId}&property_id=${farmId}&callback_uri=${callbackUri}`,
  CREATE_FARM_DEEP_ACTION: () => `${PRIVATE_ROUTE_PREFIX_FORWARD}/deep-actions/create-property`,
  EDIT_FIELD_DEEP_ACTION: ({ callbackUri, orgId, farmId, fieldId }) =>
    `${PRIVATE_ROUTE_PREFIX_FORWARD}/deep-actions/edit-field?org_id=${orgId}&property_id=${farmId}&field_id=${fieldId}&callback_uri=${callbackUri}`,
  ACTIVATE_NEW_PLAN_DEEP_ACTION: ({ callbackUri, workspaceId }) =>
    `${PRIVATE_ROUTE_PREFIX_FORWARD}/deep-actions/activate-new-plan?workspace=${workspaceId}&callback_uri=${callbackUri}`,
  AVAILABLE_PRODUCTS_DEEP_ACTION: ({ workspaceId, confirmationMethod, callbackUri }) =>
    `${PRIVATE_ROUTE_PREFIX_FORWARD}/deep-actions/available-products?workspace=${workspaceId}&confirmation_method=${confirmationMethod}&callback_uri=${callbackUri}`,
  CREATE_REGION_DRAW: ({ orgId, farmId }) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/create-region/draw-region`,
  CREATE_REGION_DETECT_FIELD: ({ orgId, farmId }) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/create-region/detect-field-region`,
  CREATE_SUB_REGION_DRAW: ({ orgId, farmId, parentRegionId }) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/region/${parentRegionId}/create-region/draw-region`,
  EDIT_REGION_DRAW: ({ orgId, farmId, regionId }) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/edit-region/${regionId}`,
  EDIT_SUB_REGION_DRAW: ({ orgId, farmId, parentRegionId, regionId }) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/region/${parentRegionId}/edit-region/${regionId}`,
  CREATE_REGION_SHAPE_FILE_UPLOADER: ({ orgId, farmId }) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/create-region/uploader`,
  CREATE_SUB_REGION_SHAPE_FILE_UPLOADER: ({ orgId, farmId, parentRegionId }) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/region/${parentRegionId}/create-region/uploader`,
  IMPORT_ATTR_IN_REGION_UPLOADER: ({ orgId, farmId }) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/create-region/import-attributes`,
  IMPORT_ATTR_IN_SUB_REGION_UPLOADER: ({ orgId, farmId, parentRegionId }) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/region/${parentRegionId}/create-region/import-attributes`,
  CREATE_REGION_SHAPE_FILE_LIST: ({ orgId, farmId }) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/create-region/uploaded-list`,
  CREATE_SUB_REGION_SHAPE_FILE_LIST: ({ orgId, farmId, parentRegionId }) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/region/${parentRegionId}/create-region/uploaded-list`,
  ALL_REGIONS: ({ orgId, farmId }) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/all-regions`,

  EDIT_REGION_DRAW_UPLOADED: ({ orgId, farmId }) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/edit-region/upload`,
  EDIT_SUB_REGION_DRAW_UPLOADED: ({ orgId, farmId, parentRegionId }) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/region/${parentRegionId}/edit-region/upload`,
  ASSOCIATE_NEW_CROP_IN_REGION: ({ orgId, farmId }) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/regions/associate-new-crop`,
  MANAGE_REGIONS: ({ orgId, farmId }) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/regions/manage-regions`,

  FIELD_TIMELINE: ({ orgId, farmId }) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/timeline`
};

export const publicItems = ['/', '/login', '/my-services', '/authenticate'];
