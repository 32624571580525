import { request } from '../utils/axios';
import { getHeaders } from '../utils/common-methods';
import {
  UPDATE_PROFILE_SETTINGS_LOADING,
  UPDATE_PROFILE_SETTINGS_SUCCESS,
  UPDATE_PROFILE_SETTINGS_ERROR
} from './types';

export function updateProfileSettingsLoading() {
  return {
    type: UPDATE_PROFILE_SETTINGS_LOADING
  };
}

export function updateProfileSettingsError(error) {
  return {
    type: UPDATE_PROFILE_SETTINGS_ERROR,
    payload: error
  };
}

export function updateProfileSettingsSuccess(profileDetails) {
  return {
    type: UPDATE_PROFILE_SETTINGS_SUCCESS,
    payload: profileDetails
  };
}

export const updateProfileSettings = (
  profileSettingsDetails,
  profileId,
  includeExtended = false
) => {
  const url = `/v2/accounts/${profileId}`.concat(includeExtended ? '?include_extended=true' : '');
  return (dispatch) => {
    dispatch(updateProfileSettingsLoading());
    const params = { ...profileSettingsDetails };
    if (params.phone === '') {
      delete params.phone;
    }
    return request({
      method: 'put',
      url,
      headers: getHeaders(),
      data: params
    })
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(updateProfileSettingsSuccess(res.data));
      })
      .catch((error) => {
        dispatch(updateProfileSettingsError(error.response.data));
        return error;
      });
  };
};
