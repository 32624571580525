import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { FontIcon } from '../common/IconComponent';

const fontFamily = 'Noto Sans, sans-serif';

export const StyledContainer = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0px;
  height: 96px;
  left: 0px;
  background: #232630;
  opacity: 0.9;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px -4px 8px rgba(47, 48, 49, 0.2);
`;

export const ButtonContainer = styled.div`
  display: flex;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  height: 40px;
  min-width: 144px;
  margin: 0px 0;
  font-family: Noto Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 0px;
  background: #14803c;
  border-radius: 4px;
  line-height: 17px;
  border: 0px;
  color: #ffffff;
  &:hover {
    background-color: #19a04b;
    border: 1px solid #19a04b;
    color: #ffffff;
  }
  &.ant-btn-primary[disabled] {
    background-color: #c1c5c8;
    color: #e8eaed;
  }
`;

export const ConfirmAccountNotification = () => {
  const { t } = useTranslation();
  return (
    <StyledContainer>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <FontIcon
          name='alert-triangle1'
          style={{ marginRight: '13px', width: '22px', height: '20px', fontSize: '21px' }}
        />
        <div style={{ marginRight: '40px' }}>
          <div
            style={{
              fontFamily: fontFamily,
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: '16px',
              lineHeight: '24px',
              letterSpacing: '-0.0075em',
              color: '#FFFFFF'
            }}>
            {t('Access your e-mail to confirm your account within 7 days.')}
          </div>
          <div
            style={{
              fontFamily: fontFamily,
              fontStyle: 'normal',
              fontWeight: 'normal',
              fontSize: '12px',
              lineHeight: '20px',
              letterSpacing: '-0.0075em',
              color: ' #FFFFFF'
            }}>
            {t(
              'If you do not confirm within this time, your account and all data entered will be deleted.'
            )}
          </div>
        </div>
      </div>
      <ButtonContainer>
        <FontIcon
          name='email-glyph1'
          style={{ marginRight: '3px', width: '22px', height: '16px', fontSize: '18px' }}
        />
        <span
          style={{
            fontFamily: fontFamily,
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '14px'
          }}>
          {t('Resend e-mail')}
        </span>
      </ButtonContainer>
    </StyledContainer>
  );
};
