import { request } from '../utils/axios';
import { getHeaders, clearAccessToken, getAppHostsInfo } from '../utils/common-methods';
import {
  FETCH_USER_PROFILE_SUCCESS,
  FETCH_USER_PROFILE_ERROR,
  FETCH_USER_PROFILE_LOADING,
  FETCH_USER_WORKSPACE_SUCCESS,
  FETCH_USER_WORKSPACE_ERROR,
  FETCH_USER_WORKSPACE_LOADING
} from './types';

export function fetchUserProfileLoading() {
  return {
    type: FETCH_USER_PROFILE_LOADING
  };
}

export function fetchUserProfileError(error) {
  return {
    type: FETCH_USER_PROFILE_ERROR,
    payload: error
  };
}

export function fetchUserProfileSuccess(user) {
  return {
    type: FETCH_USER_PROFILE_SUCCESS,
    payload: user
  };
}

export const fetchUserProfile = () => {
  return async (dispatch) => {
    dispatch(fetchUserProfileLoading());
    try {
      const res = await request.get(
        `/v2/accounts/me?include_extended=true&attributes=licensing_accounts`,
        {
          headers: getHeaders(),
          useCache: false
        }
      );
      dispatch(fetchUserProfileSuccess(res.data));
      return res.data;
    } catch (error) {
      dispatch(fetchUserProfileError(error));
      Promise.reject(error);
    }
    return null;
  };
};

export const logoutUser = async () => {
  try {
    const { account } = getAppHostsInfo();
    const myLogoutRequest = new Request(`https://${account}/exit`, {
      method: 'GET',
      mode: 'no-cors',
      credentials: 'include'
    });
    await fetch(myLogoutRequest);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('error', error);
  }
  clearAccessToken();
};

export function fetchUserWorkspaceLoading() {
  return {
    type: FETCH_USER_WORKSPACE_LOADING
  };
}

export function fetchUserWorkspaceError(error) {
  return {
    type: FETCH_USER_WORKSPACE_ERROR,
    payload: error
  };
}

export function fetchUserWorkspaceSuccess(user) {
  return {
    type: FETCH_USER_WORKSPACE_SUCCESS,
    payload: user
  };
}

export const fetchUserWorkspace = () => async (dispatch) => {
  dispatch(fetchUserWorkspaceLoading());
  try {
    const res = await request.get(
      `/v2/accounts/me?include_extended=true&attributes=licensing_accounts`,
      {
        headers: getHeaders(),
        useCache: false
      }
    );
    if (res && res.data) {
      const defaultWorkspaceId =
        res.data.default_licensing_account_id || res.data.default_workspace_id;
      const resWithWorkspace = await request.get(`/v2/licensing/accounts/${defaultWorkspaceId}`, {
        headers: getHeaders(),
        useCache: false
      });
      dispatch(fetchUserWorkspaceSuccess(resWithWorkspace.data));
      return resWithWorkspace.data;
    }
  } catch (error) {
    dispatch(fetchUserWorkspaceError(error));
  }
  return null;
};

export const fetchWorkspaceById = (workspaceId) => async (dispatch) => {
  dispatch(fetchUserWorkspaceLoading());
  try {
    const res = await request.get(`/v2/licensing/accounts/${workspaceId}`, {
      headers: getHeaders(),
      useCache: false
    });
    if (res && res.data) {
      dispatch(fetchUserWorkspaceSuccess(res.data));
      return res.data;
    }
  } catch (error) {
    dispatch(fetchUserWorkspaceError(error));
    return error;
  }
  return null;
};
