import * as Rox from 'rox-browser';
import { SETUP_ROLLOUT, SETUP_ROLLOUT_SUCCESS, SETUP_ROLLOUT_ERROR } from './types';

// Create a Roxflag in the flags container class
const featureFlags = {
  isFieldVersionFeatureVisible: new Rox.Flag(true),
  isPropertyExtraOptionVisible: new Rox.Flag(true),
  isTimelineFeatureVisible: new Rox.Flag(true),
  isNoBoundaryCreationEnable: new Rox.Flag(false)
};

export const getFeatureFlags = (context) => {
  const targetObj = {};
  Object.keys(featureFlags).forEach((flagKey) => {
    // eslint-disable-next-line
    if (featureFlags[flagKey]._type === 'boolean') {
      targetObj[flagKey] = featureFlags[flagKey].isEnabled(context);
    } else {
      targetObj[flagKey] = featureFlags[flagKey].getValue(context);
    }
    return flagKey;
  });
  return targetObj;
};

export function initRollout(configs) {
  const { forceDefault, userContext } = configs || {};
  return (dispatch) => {
    dispatch({ type: SETUP_ROLLOUT });
    Rox.register('', featureFlags);
    if (forceDefault) {
      dispatch(rolloutSuccess(getFeatureFlags(featureFlags)));
    } else {
      // Set global context
      Rox.setContext(userContext);
      Rox.setCustomStringProperty('user.id', userContext.id);
      return Rox.setup(process.env.REACT_APP_ROLLOUT_KEY, {})
        .then(() => {
          setTimeout(() => {
            // eslint-disable-next-line
            console.log('featureFlags initialized - ', getFeatureFlags(featureFlags));
            dispatch(rolloutSuccess(getFeatureFlags(featureFlags)));
          }, 100);
        })
        .catch((error) => {
          return dispatch(rolloutError(error));
        });
    }
  };
}

export function rolloutSuccess(payload) {
  return {
    type: SETUP_ROLLOUT_SUCCESS,
    payload: payload
  };
}
export function rolloutError(error) {
  return {
    type: SETUP_ROLLOUT_ERROR,
    error
  };
}
