import cloneDeep from 'lodash/cloneDeep';
import {
  //equipments
  FETCH_EQUIPMENTS_DATA_LOADING,
  FETCH_EQUIPMENTS_DATA_SUCCESS,
  FETCH_EQUIPMENTS_DATA_ERROR,
  ADD_EQUIPMENT_LOADING,
  ADD_EQUIPMENT_SUCCESS,
  ADD_EQUIPMENT_ERROR,
  EDIT_EQUIPMENT_LOADING,
  EDIT_EQUIPMENT_SUCCESS,
  EDIT_EQUIPMENT_ERROR,
  DELETE_EQUIPMENT_LOADING,
  DELETE_EQUIPMENT_SUCCESS,
  DELETE_EQUIPMENT_ERROR,
  RESET_EQUIPMENT_STORE,

  //executers
  FETCH_EXECUTERS_DATA_LOADING,
  FETCH_EXECUTERS_DATA_SUCCESS,
  FETCH_EXECUTERS_DATA_ERROR,
  ADD_EXECUTERS_LOADING,
  ADD_EXECUTERS_SUCCESS,
  ADD_EXECUTERS_ERROR,
  EDIT_EXECUTERS_LOADING,
  EDIT_EXECUTERS_SUCCESS,
  EDIT_EXECUTERS_ERROR,
  DELETE_EXECUTERS_LOADING,
  DELETE_EXECUTERS_SUCCESS,
  DELETE_EXECUTERS_ERROR,
  RESET_EXECUTER_STORE,

  //products
  FETCH_PRODUCTS_DATA_LOADING,
  FETCH_PRODUCTS_DATA_SUCCESS,
  FETCH_PRODUCTS_DATA_ERROR,
  ADD_PRODUCTS_LOADING,
  ADD_PRODUCTS_SUCCESS,
  ADD_PRODUCTS_ERROR,
  EDIT_PRODUCTS_LOADING,
  EDIT_PRODUCTS_SUCCESS,
  EDIT_PRODUCTS_ERROR,
  DELETE_PRODUCTS_LOADING,
  DELETE_PRODUCTS_SUCCESS,
  DELETE_PRODUCTS_ERROR,
  RESET_PRODUCT_STORE
} from '../actions/types';

const PRODUCT_TYPES = [
  'VARIETY',
  'FUEL',
  'PART',
  'FERTILIZER',
  'GENERIC_PRODUCT',
  'PESTICIDE',
  'ADJUVANT',
  'LUBRICANT',
  'INOCULANT',
  'ACARICIDE',
  'MICROBIOLOGICAL_ACARICIDE',
  'BIOLOGICAL_CONTROL_AGENT',
  'PLANT_ACTIVATOR',
  'BACTERICIDE',
  'MICROBIOLOGICAL_BACTERICIDE',
  'TERMITICIDE',
  'PHEROMONE',
  'FORMICIDE',
  'FUNGICIDE',
  'MICROBIOLOGICAL_FUNGICIDE',
  'HERBICIDE',
  'INSECTICIDE',
  'FUMIGANT_INSECTICIDE',
  'MICROBIOLOGICAL_INSECTICIDE',
  'MOLLUSCICIDE',
  'NEMATICIDE',
  'MICROBIOLOGICAL_NEMATICIDE',
  'SEED_PROTECTOR',
  'GROWTH_REGULATOR'
];

const initialState = {
  equipmentsLoading: false,
  equipmentsError: null,
  equipmentsData: [],
  equipmentsDataSuccess: false,
  equipmentsAddLoading: false,
  equipmentsAddError: null,
  equipmentsAddSuccess: false,
  equipmentsEditLoading: false,
  equipmentsEditError: null,
  equipmentsEditSuccess: false,
  equipmentsDeleteLoading: false,
  equipmentsDeleteError: null,
  equipmentsDeleteSuccess: false,

  executersLoading: false,
  executersError: null,
  executersData: [],
  executersDataSuccess: false,
  executersAddLoading: false,
  executersAddError: null,
  executersAddSuccess: false,
  executersEditLoading: false,
  executersEditError: null,
  executersEditSuccess: false,
  executersDeleteLoading: false,
  executersDeleteError: null,
  executersDeleteSuccess: false,
  executersTotalCount: 0,

  productsLoading: false,
  productsError: null,
  productsData: [],
  productsDataSuccess: false,
  productsAddLoading: false,
  productsAddError: null,
  productsAddSuccess: false,
  productsEditLoading: false,
  productsEditError: null,
  productsEditSuccess: false,
  productsDeleteLoading: false,
  productsDeleteError: null,
  productsDeleteSuccess: false,
  productsTotalCount: 0,

  productTypes: PRODUCT_TYPES
};

export const resources = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EQUIPMENTS_DATA_LOADING:
      return {
        ...state,
        equipmentsLoading: true,
        equipmentsDataSuccess: false
      };
    case FETCH_EQUIPMENTS_DATA_SUCCESS:
      return {
        ...state,
        equipmentsData: action.payload,
        equipmentsLoading: false,
        equipmentsDataSuccess: true
      };
    case FETCH_EQUIPMENTS_DATA_ERROR:
      return {
        ...state,
        equipmentsError: action.error,
        equipmentsLoading: false,
        equipmentsDataSuccess: false
      };

    //equipments
    case ADD_EQUIPMENT_LOADING:
      return {
        ...state,
        equipmentsAddLoading: true,
        equipmentsAddSuccess: false,
        equipmentsAddError: null,
        equipmentsEditSuccess: false,
        equipmentsEditError: null,
        equipmentsDeleteSuccess: false,
        equipmentsDeleteError: null
      };
    case ADD_EQUIPMENT_SUCCESS:
      return {
        ...state,
        equipmentsData: [action.payload, ...state.equipmentsData],

        equipmentsAddLoading: false,
        equipmentsAddSuccess: true
      };
    case ADD_EQUIPMENT_ERROR:
      return {
        ...state,
        equipmentsAddError: action.error,
        equipmentsAddLoading: false,
        equipmentsAddSuccess: false
      };

    case EDIT_EQUIPMENT_LOADING:
      return {
        ...state,
        equipmentsEditLoading: true,
        equipmentsEditSuccess: false,
        equipmentsAddSuccess: false,
        equipmentsAddError: null,
        equipmentsEditError: null,
        equipmentsDeleteSuccess: false,
        equipmentsDeleteError: null
      };
    case EDIT_EQUIPMENT_SUCCESS:
      var indexEquipments = state.equipmentsData.findIndex((item) => item.id === action.payload.id);
      const updateEquipments = cloneDeep(state.equipmentsData);
      updateEquipments.splice(indexEquipments, 1, action.payload);

      return {
        ...state,
        equipmentsData: updateEquipments,
        equipmentsEditLoading: false,
        equipmentsEditSuccess: true
      };
    case EDIT_EQUIPMENT_ERROR:
      return {
        ...state,
        equipmentsEditError: action.error,
        equipmentsEditLoading: false,
        equipmentsEditSuccess: false
      };
    case DELETE_EQUIPMENT_LOADING:
      return {
        ...state,
        equipmentsDeleteLoading: true,
        equipmentsDeleteSuccess: false,
        equipmentsAddSuccess: false,
        equipmentsAddError: null,
        equipmentsEditSuccess: false,
        equipmentsEditError: null,
        equipmentsDeleteError: null
      };
    case DELETE_EQUIPMENT_SUCCESS:
      const filteredEquipments = state.equipmentsData.filter((item) => item.id !== action.payload);
      return {
        ...state,
        equipmentsData: filteredEquipments,
        equipmentsDeleteLoading: false,
        equipmentsDeleteSuccess: true
      };
    case DELETE_EQUIPMENT_ERROR:
      return {
        ...state,
        equipmentsDeleteError: action.error,
        equipmentsDeleteLoading: false,
        equipmentsDeleteSuccess: false
      };
    case RESET_EQUIPMENT_STORE:
      return {
        ...state,
        equipmentsLoading: false,
        equipmentsError: null,
        equipmentsDataSuccess: false,
        equipmentsAddLoading: false,
        equipmentsAddError: null,
        equipmentsAddSuccess: false,
        equipmentsEditLoading: false,
        equipmentsEditError: null,
        equipmentsEditSuccess: false,
        equipmentsDeleteLoading: false,
        equipmentsDeleteError: null,
        equipmentsDeleteSuccess: false
      };

    //executers
    case FETCH_EXECUTERS_DATA_LOADING:
      return {
        ...state,
        executersLoading: true,
        executersDataSuccess: false,
        executersEditSuccess: false,
        executersAddSuccess: false,
        executersDeleteSuccess: false,
        executersEditError: false,
        executersAddError: false,
        executersDeleteError: false
      };
    case FETCH_EXECUTERS_DATA_SUCCESS:
      return {
        ...state,
        executersData: action.payload,
        executersLoading: false,
        executersDataSuccess: true,
        executersTotalCount: action.payload.total_elements
      };
    case FETCH_EXECUTERS_DATA_ERROR:
      return {
        ...state,
        executersError: action.error,
        executersLoading: false,
        executersDataSuccess: false
      };

    case ADD_EXECUTERS_LOADING:
      return {
        ...state,
        executersAddLoading: true,
        executersAddSuccess: false,
        executersEditSuccess: false,
        executersDeleteSuccess: false,
        executersEditError: false,
        executersAddError: false,
        executersDeleteError: false
      };
    case ADD_EXECUTERS_SUCCESS:
      return {
        ...state,
        executersData: [...state.executersData, action.payload],

        executersAddLoading: false,
        executersAddSuccess: true

        // ...state,
        // data: [...state.data, action.payload],

        // updateLoading: false
      };
    case ADD_EXECUTERS_ERROR:
      return {
        ...state,
        executersAddError: action.error,
        executersAddLoading: false,
        executersAddSuccess: false
      };
    case EDIT_EXECUTERS_LOADING:
      return {
        ...state,
        executersEditLoading: true,
        executersEditSuccess: false,
        executersAddSuccess: false,
        executersDeleteSuccess: false,
        executersEditError: false,
        executersAddError: false,
        executersDeleteError: false
      };
    case EDIT_EXECUTERS_SUCCESS:
      var indexExecuters = state.executersData.findIndex((item) => item.id === action.payload.id);
      const updateExecutors = cloneDeep(state.executersData);
      updateExecutors.splice(indexExecuters, 1, action.payload);

      return {
        ...state,
        executersData: updateExecutors,
        executersEditLoading: false,
        executersEditSuccess: true
      };
    case EDIT_EXECUTERS_ERROR:
      return {
        ...state,
        executersEditError: action.error,
        executersEditLoading: false,
        executersEditSuccess: false
      };

    case DELETE_EXECUTERS_LOADING:
      return {
        ...state,
        executersDeleteLoading: true,
        executersDeleteSuccess: false,
        executersEditSuccess: false,
        executersAddSuccess: false,
        executersEditError: false,
        executersAddError: false,
        executersDeleteError: false
      };
    case DELETE_EXECUTERS_SUCCESS:
      var newExecutersData = state.executersData.filter((item) => item.id !== action.payload);

      return {
        ...state,
        executersData: newExecutersData,
        executersDeleteLoading: false,
        executersDeleteSuccess: true
      };
    case DELETE_EXECUTERS_ERROR:
      return {
        ...state,
        executersDeleteError: action.error,
        executersDeleteLoading: false,
        executersDeleteSuccess: false
      };
    case RESET_EXECUTER_STORE:
      return {
        ...state,
        executersLoading: false,
        executersError: null,
        executersDataSuccess: false,
        executersAddLoading: false,
        executersAddError: null,
        executersAddSuccess: false,
        executersEditLoading: false,
        executersEditError: null,
        executersEditSuccess: false,
        executersDeleteLoading: false,
        executersDeleteError: null,
        executersDeleteSuccess: false
      };

    //Products
    case FETCH_PRODUCTS_DATA_LOADING:
      return {
        ...state,
        productsLoading: true,
        productsDataSuccess: false
      };
    case FETCH_PRODUCTS_DATA_SUCCESS:
      let data = [];
      if (action.payload.page === 0) {
        data = action.payload.content.content;
      } else {
        data = [...state.productsData, ...action.payload.content.content];
      }
      return {
        ...state,
        productsData: data,
        productsLoading: false,
        productsDataSuccess: true,
        productsTotalCount: action.payload.total_elements
      };
    case FETCH_PRODUCTS_DATA_ERROR:
      return {
        ...state,
        productsError: action.error,
        productsLoading: false,
        productsDataSuccess: false
      };
    case ADD_PRODUCTS_LOADING:
      return {
        ...state,
        productsAddLoading: true,
        productsAddSuccess: false,
        productsEditSuccess: false,
        productsDeleteSuccess: false,
        productsAddError: null,
        productsEditError: null,
        productsDeleteError: null
      };
    case ADD_PRODUCTS_SUCCESS:
      return {
        ...state,
        productsData: [...state.productsData, action.payload],
        productsAddLoading: false,
        productsAddSuccess: true
      };
    case ADD_PRODUCTS_ERROR:
      return {
        ...state,
        productsAddError: action.error,
        productsAddLoading: false,
        productsAddSuccess: false
      };

    case EDIT_PRODUCTS_LOADING:
      return {
        ...state,
        productsEditLoading: true,
        productsEditSuccess: false,
        productsAddSuccess: false,
        productsDeleteSuccess: false,
        productsAddError: null,
        productsEditError: null,
        productsDeleteError: null
      };
    case EDIT_PRODUCTS_SUCCESS:
      var indexProducts = state.productsData.findIndex((item) => item.id === action.payload.id);
      const updateProducts = cloneDeep(state.productsData);
      updateProducts.splice(indexProducts, 1, action.payload);

      return {
        ...state,
        productsData: updateProducts,
        productsEditLoading: false,
        productsEditSuccess: true
      };
    case EDIT_PRODUCTS_ERROR:
      return {
        ...state,
        productsEditError: action.error,
        productsEditLoading: false,
        productsEditSuccess: false
      };

    case DELETE_PRODUCTS_LOADING:
      return {
        ...state,
        productsDeleteLoading: true,
        productsDeleteSuccess: false,
        productsEditSuccess: false,
        productsAddSuccess: false,
        productsAddError: null,
        productsEditError: null,
        productsDeleteError: null
      };
    case DELETE_PRODUCTS_SUCCESS:
      var delProduct = state.productsData.filter((item) => item.id !== action.payload);

      return {
        ...state,
        productsData: delProduct,
        productsDeleteLoading: false,
        productsDeleteSuccess: true
      };
    case DELETE_PRODUCTS_ERROR:
      return {
        ...state,
        productsDeleteError: action.error,
        productsDeleteLoading: false,
        productsDeleteSuccess: false
      };
    case RESET_PRODUCT_STORE:
      return {
        ...state,
        productsLoading: false,
        productsError: null,
        productsDataSuccess: false,
        productsAddLoading: false,
        productsAddError: null,
        productsAddSuccess: false,
        productsEditLoading: false,
        productsEditError: null,
        productsEditSuccess: false,
        productsDeleteLoading: false,
        productsDeleteError: null,
        productsDeleteSuccess: false
      };
    default:
      return state;
  }
};
