//Called Workspace and orgs API sequentially to ensure we have all the required workspace query parameters before getting the orgs(SB-2999)
export const getWorkspaceAndOrgsSequentially = async ({
  fetchOrgsList = () => null,
  fetchAllOrgs = () => null,
  fetchUserWorkspace = () => null
}) => {
  try {
    await fetchUserWorkspace();
    await fetchOrgsList();
    await fetchAllOrgs();
  } catch (e) {
    //Error handling done in redux actions
  }
};
