/* eslint-disable no-param-reassign */
/**
 * i18n : https://react.i18next.com/latest/using-with-hooks
 */
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import moment from 'moment';
import {
  getLanguageCode,
  getLocaleTempUsingCookies,
  getLocaleUsingCookies
} from './utils/common-methods';

const lng = getLanguageCode(getLocaleTempUsingCookies()) || getLocaleUsingCookies() || 'en';
moment.locale(lng);

i18n
  .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init(
    {
      preload: ['en'],
      // fallbackLng: 'en',
      lng: lng,
      react: {
        useSuspense: false
      },
      debug: process.env.node_env !== 'production',
      //keySeparator: false, // we use content as keys
      interpolation: {
        escapeValue: false
      },
      backend: {
        loadPath: '/i18n/{{lng}}.json'
      }
    },
    function () {
      const orgFn = i18n.services.resourceStore.getResource;
      i18n.services.resourceStore.getResource = function (code, ns, key, options) {
        let value = orgFn.call(i18n.services.resourceStore, code, ns, key, options);
        if (value) {
          return value;
        }
        options = options || {};
        options.keySeparator = '##############';
        value = orgFn.call(i18n.services.resourceStore, code, ns, key, options);
        return value;
      };
    } // callback will be called once all resources are loaded then we are customizing function of getvalue of resource store
  );

// eslint-disable-next-line
export default i18n;
