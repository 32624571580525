/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { marked } from 'marked';
import { getTranslationByCode } from 'utils/getErrorMessageByCode';
import { TxtLoader } from 'components/Loader/Loader';
import { AppConstants } from 'utils/app.constants';
import { filterTextForBrandName } from 'utils/common-methods';
import { history, URL_CONSTANTS } from 'utils/history';
import { StyledModalWhatsNew } from './InfoCarousel.style';
import Whatsnewicon from '../../public/whatsnewicon.svg';
import { UPDATES } from './whatsNew/whatsNewUpdates';

const displayInfo = UPDATES[0];

export const WhatsNewHelper = (whatsNewSeenList = '', signedUpAt) => {
  let seenInfoArr = [];
  if (whatsNewSeenList) {
    seenInfoArr = whatsNewSeenList.split(',');
  }
  const showMeWhatsNew = () => {
    return (
      !seenInfoArr.includes(displayInfo.displayKey) &&
      moment(displayInfo.date).diff(moment(signedUpAt), 'hours') >
        AppConstants.WHATS_NEW_OFF_FOR_NEW_USER
    );
  };

  const getUpdatedSeenWhatsNewList = () => {
    return [...new Set([...seenInfoArr, displayInfo.displayKey])].join(',');
  };

  return {
    showMeWhatsNew,
    getUpdatedSeenWhatsNewList
  };
};

export const ModalWhatsNew = (props) => {
  const { t } = useTranslation();
  const [image, setImage] = useState({});
  const [loading, setLoading] = useState(true);
  const isDeepAction = window.location.href.includes('deep-actions');

  const openDeckOfApps = () => {
    const deckOfAppsIcon = document.getElementsByClassName('cw-deck-of-apps-icon')[0];
    if (deckOfAppsIcon) {
      props.handleWhatsNewComplete();
      deckOfAppsIcon.click();
    }
  };
  useEffect(() => {
    if (props.whatsNewModalVisible && !isDeepAction) {
      setTimeout(() => {
        const whatsNewActionEle = document.getElementsByClassName('whatsnew-action');
        const handler = function () {
          // eslint-disable-next-line
          const action = this.getAttribute('id');
          switch (action) {
            case 'menu':
              openDeckOfApps();
              break;
            case 'field-detection-tool':
              props.handleWhatsNewComplete();
              const sortedOrgsByDate = props.orgsList.sort(
                (a, b) => new Date(new Date(a.created_at)) - new Date(new Date(b.created_at))
              );
              if (props.propertiesList.result.length !== 0) {
                const sortedPropsByDate = Object.keys(
                  props.propertiesList.entities.properties
                ).sort(
                  (a, b) =>
                    new Date(new Date(props.propertiesList.entities.properties[a].created_at)) -
                    new Date(new Date(props.propertiesList.entities.properties[b].created_at))
                );
                if (sortedOrgsByDate && sortedPropsByDate) {
                  history.push(
                    URL_CONSTANTS.CREATE_DETECT_FIELD({
                      orgId: sortedOrgsByDate[0].id,
                      farmId: sortedPropsByDate[0]
                    })
                  );
                }
              } else {
                if (sortedOrgsByDate && sortedOrgsByDate[0]) {
                  history.push(
                    URL_CONSTANTS.LANDING_ORGANIZATION({
                      orgId: sortedOrgsByDate[0].id
                    })
                  );
                }
              }
              break;
            case 'deck-of-app-icon':
              openDeckOfApps();
              break;
            default: //do nothing
          }
        };
        for (const el of whatsNewActionEle) {
          el.addEventListener('click', handler, false);
        }
      }, 500);
    }
  }, [props.whatsNewModalVisible, props.propertiesList, props.orgsList]);

  useEffect(() => {
    if (
      props.whatsNewModalVisible &&
      props.isWhatsNewVisibleGlobalState &&
      Object.keys(image).length < 2 &&
      !isDeepAction
    ) {
      setImage({});
      UPDATES.forEach((el) => {
        // eslint-disable-next-line prefer-template
        import('../../public/' + el.image).then((img) => {
          setImage((prev) => ({ ...prev, [el.image]: img.default }));
        });
      });
    }
    setTimeout(() => {
      setLoading(false);
    }, 100);
  }, [props.whatsNewModalVisible, props.isWhatsNewVisibleGlobalState]);

  useEffect(() => {
    if (!props.isWhatsNewVisibleGlobalState) {
      // eslint-disable-next-line prefer-template
      import('../../public/' + displayInfo.image).then((img) => {
        setImage({ [displayInfo.image]: img.default });
        setTimeout(() => {
          setLoading(false);
        }, 100);
      });
    }
  }, []);

  const ModalHeader = () => {
    return (
      <div>
        <img src={Whatsnewicon} alt='whatsnewicon' />
        <span className='title-text'>{t('What’s new')}</span>
      </div>
    );
  };

  const ModalFooter = () => {
    const openAssistant = () => {
      props.handleWhatsNewComplete();
      const assistantIcon = document.getElementsByClassName('cw-assistant-icon')[0];
      if (assistantIcon) {
        assistantIcon.click();
      } else {
        let orgId = '';
        if (props.user && props.user.auto_created_org_id) {
          orgId = props.user.auto_created_org_id;
        } else if (props.orgs && props.orgs.length > 0) {
          orgId = props.orgs[0];
        }
        if (orgId !== '') {
          const newUrl = URL_CONSTANTS.LANDING_ORGANIZATION({
            orgId: orgId
          });
          history.push(newUrl);
        }
        const delay = 300;
        setTimeout(() => {
          const assistantIcon2 = document.getElementsByClassName('cw-assistant-icon')[0];
          if (assistantIcon2) {
            assistantIcon2.click();
          }
        }, delay);
      }
    };

    return (
      <div>
        <span className='footer-text' onClick={openAssistant}>
          {t('For more updates, visit the Assistant')}
        </span>
      </div>
    );
  };

  const getDeckOfAppsText = (key) => {
    const markdownContent = filterTextForBrandName(
      getTranslationByCode(UPDATES[key].displayKey, 'en')
    );
    const convertedContent = () => {
      return marked.parse(markdownContent);
    };
    return (
      <div className='item-text' dangerouslySetInnerHTML={{ __html: convertedContent() }}>
        {/* <ReactMarkdown>{markdownContent}</ReactMarkdown> */}
      </div>
    );
  };

  const contentJson = [];

  if (!props.isWhatsNewVisibleGlobalState) {
    contentJson.push({
      title: <span>{t(filterTextForBrandName(displayInfo.title))}</span>,
      date: displayInfo.date,
      image: image[displayInfo.image],
      text: getDeckOfAppsText(0)
    });
  } else {
    UPDATES.forEach((el, key) => {
      return contentJson.push({
        title: <span>{t(filterTextForBrandName(el.title))}</span>,
        date: el.date,
        image: image[el.image],
        text: getDeckOfAppsText(key)
      });
    });
  }
  const getContentListItem = (item, key) => {
    if (loading) {
      return <TxtLoader />;
    }
    if (props.isWhatsNewVisibleGlobalState && Object.keys(image).length > 1 && key >= 1) {
      return (
        <div style={{ marginBottom: '23px' }} key={key}>
          <div className='line-divider' />
          <p className='item-title'>{item.title}</p>
          <p className='item-date'>{item.date}</p>
          {item.image !== '' && <img className='item-image' src={item.image} alt='gif' />}
          {item.text}
        </div>
      );
    } else {
      return (
        <div style={{ marginBottom: '23px' }} key={key}>
          <p className='item-title'>{item.title}</p>
          <p className='item-date'>{item.date}</p>
          {item.image !== '' && <img className='item-image' src={item.image} alt='gif' />}
          {item.text}
        </div>
      );
    }
  };

  return (
    <StyledModalWhatsNew
      title={<ModalHeader />}
      centered
      footer={!props.isWhatsNewVisibleGlobalState ? <ModalFooter /> : null}
      visible={props.whatsNewModalVisible && !isDeepAction}
      onCancel={() => {
        props.handleWhatsNewComplete();
      }}
      maskClosable={false}>
      {props.isWhatsNewVisibleGlobalState ? (
        <div className='modal-content' data-testid='whats-new-modal-content'>
          {contentJson.map((item, key) => {
            return getContentListItem(item, key);
          })}
        </div>
      ) : (
        <div className='modal-content' data-testid='whats-new-modal-content'>
          {getContentListItem(contentJson[0])}
        </div>
      )}
    </StyledModalWhatsNew>
  );
};

ModalWhatsNew.propTypes = {
  handleWhatsNewComplete: PropTypes.func,
  whatsNewModalVisible: PropTypes.bool,
  isWhatsNewVisibleGlobalState: PropTypes.bool,
  user: PropTypes.object,
  orgs: PropTypes.array,
  orgsList: PropTypes.array,
  propertiesList: PropTypes.object
};
