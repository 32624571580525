import moment from 'moment';

import {
  FETCH_DAILY_OBSERVATIONS_LOADING,
  FETCH_DAILY_OBSERVATIONS_SUCCESS,
  FETCH_DAILY_OBSERVATIONS_ERROR,
  FETCH_HOURLY_OBSERVATIONS_LOADING,
  FETCH_HOURLY_OBSERVATIONS_SUCCESS,
  FETCH_HOURLY_OBSERVATIONS_ERROR,
  FETCH_DAILY_FORECAST_LOADING,
  FETCH_DAILY_FORECAST_SUCCESS,
  FETCH_DAILY_FORECAST_ERROR,
  FETCH_HOURLY_FORECAST_LOADING,
  FETCH_HOURLY_FORECAST_SUCCESS,
  FETCH_HOURLY_FORECAST_ERROR,
  FETCH_SPRAY_RECOMMENDATIONS_LOADING,
  FETCH_SPRAY_RECOMMENDATIONS_SUCCESS,
  FETCH_SPRAY_RECOMMENDATIONS_ERROR
} from '../actions/types';
import {
  dateFormat,
  generateDailyGeoTrollCodes,
  getAverageDailyGeoTrollCodes
} from '../components/WeatherInfoBar/helpers';

const initialState = {
  sprayRecommendationsLoading: true,
  hourlyForecastsLoading: true,
  dailyForecastsLoading: true,
  dailyObservationsLoading: true,
  hourlyObservationsLoading: true,
  sprayRecommendationsError: null,
  hourlyForecastsError: null,
  dailyForecastsError: null,
  dailyObservationsError: null,
  hourlyObservationsError: null,
  sprayingRecommendationsDaily: null,
  sprayingRecommendationsHourly: null,
  hourlyForeCasts: null,
  dailyForeCasts: null,
  hourlyObservations: null,
  dailyObservations: null
};

const parseForecastDailyData = (result, item) => {
  const date = moment
    .parseZone(item.date)
    .locale('en')
    .set('hours', 0)
    .set('minutes', 0)
    .set('seconds', 0)
    .format(dateFormat);
  result[date] = {
    ...result[date],
    [item.property]: item.details,
    date
  };
  return result;
};
const parseForecastHourlyData = (result, item) => {
  const date = moment
    .parseZone(item.date)
    .locale('en')
    .set('minutes', 0)
    .set('seconds', 0)
    .format(dateFormat);
  result[date] = {
    ...result[date],
    [item.property]: item.details,
    date
  };
  return result;
};

export const weather = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DAILY_FORECAST_SUCCESS:
      const dailyForeCasts = action.payload.data.reduce(parseForecastDailyData, {});
      return {
        ...state,
        dailyForeCasts,
        dailyForecastsLoading: false,
        dailyForecastsError: null
      };
    case FETCH_DAILY_FORECAST_ERROR:
      return {
        ...state,
        dailyForecastsError: action.error,
        dailyForecastsLoading: false
      };
    case FETCH_DAILY_FORECAST_LOADING:
      return {
        ...state,
        dailyForecastsLoading: true
      };

    case FETCH_HOURLY_FORECAST_SUCCESS:
      const hourlyForeCasts = action.payload.data.reduce(parseForecastHourlyData, {});
      return {
        ...state,
        hourlyForeCasts,
        hourlyForecastsLoading: false,
        hourlyForecastsError: null
      };
    case FETCH_HOURLY_FORECAST_ERROR:
      return {
        ...state,
        hourlyForecastsError: action.error,
        hourlyForecastsLoading: false
      };
    case FETCH_HOURLY_FORECAST_LOADING:
      return {
        ...state,
        hourlyForecastsLoading: true
      };

    case FETCH_SPRAY_RECOMMENDATIONS_SUCCESS:
      const sprayingRecommendationsDaily = {};
      const sprayingRecommendationsHourly = action.payload.data.reduce((result, item) => {
        const timeDate = moment
          .parseZone(item.date)
          .locale('en')
          .set('minutes', 0)
          .set('seconds', 0)
          .format(dateFormat);
        result[timeDate] = {
          ...(result[timeDate] || {}),
          ...item.details,
          date: timeDate
        };

        generateDailyGeoTrollCodes(
          sprayingRecommendationsDaily,
          item.date,
          result[timeDate].geotroll_code
        );
        return result;
      }, {});
      getAverageDailyGeoTrollCodes(sprayingRecommendationsDaily);
      return {
        ...state,
        sprayingRecommendationsHourly,
        sprayingRecommendationsDaily,
        sprayRecommendationsLoading: false,
        sprayRecommendationsError: null
      };
    case FETCH_SPRAY_RECOMMENDATIONS_ERROR:
      return {
        ...state,
        sprayRecommendationsError: action.error,
        sprayRecommendationsLoading: false
      };
    case FETCH_SPRAY_RECOMMENDATIONS_LOADING:
      return {
        ...state,
        sprayRecommendationsLoading: true
      };

    case FETCH_DAILY_OBSERVATIONS_SUCCESS:
      const parsedDailyObservations = action.payload.data.reduce(parseForecastDailyData, {});
      return {
        ...state,
        dailyObservations: parsedDailyObservations,
        dailyObservationsLoading: false,
        dailyObservationsError: null
      };
    case FETCH_DAILY_OBSERVATIONS_ERROR:
      return {
        ...state,
        dailyObservationsError: action.error,
        dailyObservationsLoading: false
      };
    case FETCH_DAILY_OBSERVATIONS_LOADING:
      return {
        ...state,
        dailyObservationsLoading: true
      };
    case FETCH_HOURLY_OBSERVATIONS_SUCCESS:
      const parsedHourlyObservations = action.payload.data.reduce(parseForecastHourlyData, {});
      return {
        ...state,
        hourlyObservations: parsedHourlyObservations,
        hourlyObservationsLoading: false,
        hourlyObservationsError: null
      };
    case FETCH_HOURLY_OBSERVATIONS_ERROR:
      return {
        ...state,
        hourlyObservationsError: action.error,
        hourlyObservationsLoading: false
      };
    case FETCH_HOURLY_OBSERVATIONS_LOADING:
      return {
        ...state,
        hourlyObservationsLoading: true
      };
    default:
      return state;
  }
};
