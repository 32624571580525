import i18next from 'i18next';

export const getErrorMessageByCode = (code) => {
  return i18next.t(code) || i18next.t('Something went wrong');
};

export const getTranslationByCode = (code, fallback) => {
  const initialTranslation = i18next.t(code);
  if (initialTranslation === code) {
    return i18next.t(code, fallback ? { lng: fallback } : {});
  }
  return initialTranslation;
};
