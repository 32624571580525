export const UPDATES = [
  {
    date: '03 March, 2022',
    displayKey: 'WHATS_NEW_UPDATE_03_03_2022',
    image: 'whatsnew-field-versioning.png',
    title: 'See the editions of each field over time!'
  },
  {
    date: '23 Dec, 2021',
    displayKey: 'WHATS_NEW_UPDATE_23_12_2021',
    image: 'whatsnew-field-detection.gif',
    title: 'Cropwise draws the fields on the map for you!'
  },
  {
    date: '30 Nov, 2021',
    displayKey: 'WHATS_NEW_UPDATE_30_11_2021',
    image: 'whatsnew-timeline.gif',
    title: 'Welcome to the new version of Base!'
  },
  {
    date: '14 Sep, 2021',
    displayKey: 'WHATS_NEW_UPDATE_14_09_2021',
    image: 'whatsnew-deck-of-apps.gif',
    title: 'Cropwise Menu: Browse your farms more efficiently!'
  }
];
