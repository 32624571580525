import { request } from '../utils/axios';
import { getHeaders } from '../utils/common-methods';
import {
  GET_TERMS_CONDITIONS_LOADING,
  GET_TERMS_CONDITIONS_SUCCESS,
  GET_TERMS_CONDITIONS_ERROR
} from '../actions/types';

export function fetchTermsAndConditionsLoading() {
  return {
    type: GET_TERMS_CONDITIONS_LOADING
  };
}
export function fetchTermsAndConditionsSuccess(value) {
  return {
    type: GET_TERMS_CONDITIONS_SUCCESS,
    payload: value
  };
}
export function fetchTermsAndConditionsError(error) {
  return {
    type: GET_TERMS_CONDITIONS_ERROR,
    error
  };
}

export const fetchTermsAndConditions = () => {
  return async (dispatch) => {
    dispatch(fetchTermsAndConditionsLoading());
    try {
      const res = await request.get(
        `${process.env.REACT_APP_API_URL}/v2/terms?include_text=all&apps=all`,
        {
          headers: getHeaders(),
          useCache: true
        }
      );

      dispatch(fetchTermsAndConditionsSuccess(res.data));
      return res.data;
    } catch (error) {
      dispatch(fetchTermsAndConditionsError(error));
    }
    return null;
  };
};
