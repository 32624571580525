export const SET_ORGANIZATIONS_LIST_LOADING = 'SET_ORGANIZATIONS_LIST_LOADING';
export const SET_ORGANIZATIONS_LIST_SUCCESS = 'SET_ORGANIZATIONS_LIST_SUCCESS';
export const SET_SEARCH_ORGANIZATIONS_LIST_SUCCESS = 'SET_SEARCH_ORGANIZATIONS_LIST_SUCCESS';
export const SET_SEARCH_ORGANIZATIONS_LIST_LOADING = 'SET_SEARCH_ORGANIZATIONS_LIST_LOADING';
export const SET_ORGANIZATIONS_LIST_ERROR = 'SET_ORGANIZATIONS_LIST_ERROR';
export const SET_ALL_ORGANIZATIONS_LIST_LOADING = 'SET_ALL_ORGANIZATIONS_LIST_LOADING';
export const SET_ALL_ORGANIZATIONS_LIST_SUCCESS = 'SET_ALL_ORGANIZATIONS_LIST_SUCCESS';
export const SET_ALL_ORGANIZATIONS_LIST_ERROR = 'SET_ALL_ORGANIZATIONS_LIST_ERROR';
export const CREATE_ORG_LIST_SUCCESS = 'CREATE_ORG_LIST_SUCCESS';
export const CREATE_ORG_LIST_ERROR = 'CREATE_ORG_LIST_ERROR';
export const CREATE_ORG_LIST_LOADING = 'CREATE_ORG_LIST_LOADING';
export const DELETE_ORG_LIST_ITEM_RESET = 'DELETE_ORG_LIST_ITEM_RESET';
export const DELETE_ORG_LIST_ITEM_LOADING = 'DELETE_ORG_LIST_ITEM_LOADING';
export const DELETE_ORG_LIST_ITEM_SUCCESS = 'DELETE_ORG_LIST_ITEM_SUCCESS';
export const DELETE_ORG_LIST_ITEM_ERROR = 'DELETE_ORG_LIST_ITEM_ERROR';
export const EDIT_ORG_LIST_SUCCESS = 'EDIT_ORG_LIST_SUCCESS';
export const EDIT_ORG_LIST_ERROR = 'EDIT_ORG_LIST_ERROR';
export const EDIT_ORG_LIST_LOADING = 'EDIT_ORG_LIST_LOADING';
export const RESET_ORG_LIST = 'RESET_ORG_LIST';
export const SET_PROPERTY_BY_ID_LOADING = 'SET_PROPERTY_BY_ID_LOADING';
export const SET_PROPERTY_BY_ID_SUCCESS = 'SET_PROPERTY_BY_ID_SUCCESS';
export const SET_PROPERTY_BY_ID_ERROR = 'SET_PROPERTY_BY_ID_ERROR';
export const RESET_PROPERTY_DETAILS = 'RESET_PROPERTY_DETAILS';
export const EDIT_PROPERTIES_BY_SEASONS_SUCCESS = 'EDIT_PROPERTIES_BY_SEASONS_SUCCESS';
export const SET_ORG_PROPERTIES_LOADING = 'SET_ORG_PROPERTIES_LOADING';
export const SET_ORG_PROPERTIES_SUCCESS = 'SET_ORG_PROPERTIES_SUCCESS';
export const SET_ORG_PROPERTIES_ERROR = 'SET_ORG_PROPERTIES_ERROR';
export const SET_ORG_SEASONS_LOADING = 'SET_ORG_SEASONS_LOADING';
export const SET_ORG_SEASONS_SUCCESS = 'SET_ORG_SEASONS_SUCCESS';
export const SET_ORG_SEASONS_ERROR = 'SET_ORG_SEASONS_ERROR';
export const SET_SEASON_BY_PROPERTY = 'SET_SEASON_BY_PROPERTY';
export const SET_SEASON_BY_PROPERTY_LOADING = 'SET_SEASON_BY_PROPERTY_LOADING';
export const UPLOAD_ORG_LIST_ITEM_AVATAR_LOADING = 'UPLOAD_ORG_LIST_ITEM_AVATAR_LOADING';
export const UPLOAD_ORG_LIST_ITEM_AVATAR_SUCCESS = 'UPLOAD_ORG_LIST_ITEM_AVATAR_SUCCESS';
export const UPLOAD_ORG_LIST_ITEM_AVATAR_ERROR = 'UPLOAD_ORG_LIST_ITEM_AVATAR_ERROR';
export const REMOVE_ORG_LIST_ITEM_AVATAR_LOADING = 'REMOVE_ORG_LIST_ITEM_AVATAR_LOADING';
export const REMOVE_ORG_LIST_ITEM_AVATAR_SUCCESS = 'REMOVE_ORG_LIST_ITEM_AVATAR_SUCCESS';
export const REMOVE_ORG_LIST_ITEM_AVATAR_ERROR = 'REMOVE_ORG_LIST_ITEM_AVATAR_ERROR';
export const SET_SEASON_FIELDS_LOADING = 'SET_SEASON_FIELDS_LOADING';
export const SET_SEASON_FIELDS_SUCCESS = 'SET_SEASON_FIELDS_SUCCESS';
export const SET_SEASON_FIELDS_WITH_FIELD_IDS_AS_KEY = 'SET_SEASON_FIELDS_WITH_FIELD_IDS_AS_KEY';
export const SET_SEASON_FIELDS_ERROR = 'SET_SEASON_FIELDS_ERROR';
export const ADD_SEASON_FIELD_LOADING = 'ADD_SEASON_FIELD_LOADING';
export const ADD_SEASON_FIELD_SUCCESS = 'ADD_SEASON_FIELD_SUCCESS';
export const RESET_ADD_SEASON_FIELD = 'RESET_ADD_SEASON_FIELD';
export const EDIT_SEASON_FIELD_SUCCESS = 'EDIT_SEASON_FIELD_SUCCESS';
export const SET_EDIT_SEASON_SUCCESS = 'SET_EDIT_SEASON_SUCCESS';
export const ADD_SEASON_FIELD_ERROR = 'ADD_SEASON_FIELD_ERROR';
export const DELETE_SEASON_FIELD = 'DELETE_SEASON_FIELD';
export const SET_SEASON_BY_ID_SUCCESS = 'SET_SEASON_BY_ID_SUCCESS';
export const SET_SEASON_BY_ID_ERROR = 'SET_SEASON_BY_ID_ERROR';
export const SET_SEASON_BY_ID_LOADING = 'SET_SEASON_BY_ID_LOADING';
export const FETCH_PROPERTY_BY_ID_SUCCESS = 'FETCH_PROPERTY_BY_ID_SUCCESS';
export const FETCH_PROPERTY_BY_ID_LOADING = '  FETCH_PROPERTY_BY_ID_LOADING';
export const FETCH_PROPERTY_BY_ID_ERROR = '  FETCH_PROPERTY_BY_ID_ERROR';
export const FETCH_ORGANIZATIONS_SUCCESS = 'FETCH_ORGANIZATIONS_SUCCESS';
export const FETCH_ORGANIZATIONS_ERROR = 'FETCH_ORGANIZATIONS_ERROR';
export const FETCH_ORGANIZATIONS_LOADING = 'FETCH_ORGANIZATIONS_LOADING';
export const FETCH_ORGANIZATION_BY_ID_LOADING = 'FETCH_ORGANIZATION_BY_ID_LOADING';
export const FETCH_ORGANIZATION_BY_ID_SUCCESS = 'FETCH_ORGANIZATION_BY_ID_SUCCESS';
export const FETCH_ORGANIZATION_BY_ID_ERROR = 'FETCH_ORGANIZATION_BY_ID_ERROR';
export const TOGGLE_HEADER = 'TOGGLE_HEADER';
export const TOGGLE_DEEP_ACTION = 'TOGGLE_DEEP_ACTION';
export const SET_SELECTED_ORG = 'SET_SELECTED_ORG';
export const SET_SELECTED_PROPERTY = 'SET_SELECTED_PROPERTY';
export const SET_ORGS_DETAILS = 'SET_ORGS_DETAILS';
export const CREATE_ORG_LOADING = 'CREATE_ORG_LOADING';
export const CREATE_ORG_SUCCESS = 'CREATE_ORG_SUCCESS';
export const CREATE_ORG_ERROR = 'CREATE_ORG_ERROR';
export const CREATE_PROPERTY_SUCCESS = 'CREATE_PROPERTY_SUCCESS';
export const CREATE_PROPERTY_ERROR = 'CREATE_PROPERTY_ERROR';
export const CREATE_PROPERTY_LOADING = 'CREATE_PROPERTY_LOADING';
export const PROPERTY_LOADING = 'PROPERTY_LOADING';
export const EDIT_PROPERTY_SUCCESS = 'EDIT_PROPERTY_SUCCESS';
export const EDIT_PROPERTY_ERROR = 'EDIT_PROPERTY_ERROR';
export const SET_SELECTED_SEASON = 'SET_SELECTED_SEASON';
export const SET_SELECTED_SEASONS = 'SET_SELECTED_SEASONS';
export const FETCH_FIELDS_BY_SEASONS_PROP_ID_SUCCESS = 'FETCH_FIELDS_BY_SEASONS_PROP_ID_SUCCESS';
export const FETCH_FIELDS_BY_SEASONS_PROP_ID_ERROR = 'FETCH_FIELDS_BY_SEASONS_PROP_ID_ERROR';
export const FETCH_FIELDS_BY_SEASONS_PROP_ID_LOADING = 'FETCH_FIELDS_BY_SEASONS_PROP_ID_LOADING';
export const DELETE_PROPERTY_BY_ID_SUCCESS = 'DELETE_PROPERTY_BY_ID_SUCCESS';
export const FETCH_USER_PROFILE_SUCCESS = 'FETCH_USER_PROFILE_SUCCESS';
export const FETCH_USER_PROFILE_ERROR = 'FETCH_USER_PROFILE_ERROR';
export const FETCH_USER_PROFILE_LOADING = 'FETCH_USER_PROFILE_LOADING';
export const FETCH_USER_WORKSPACE_SUCCESS = 'FETCH_USER_WORKSPACE_SUCCESS';
export const FETCH_USER_WORKSPACE_LOADING = 'FETCH_USER_WORKSPACE_LOADING';
export const FETCH_USER_WORKSPACE_ERROR = 'FETCH_USER_WORKSPACE_ERROR';

export const DELETE_PROPERTY_BY_ID_ERROR = 'DELETE_PROPERTY_BY_ID_ERROR';
export const DELETE_PROPERTY_BY_ID_LOADING = 'DELETE_PROPERTY_BY_ID_LOADING';
export const DELETE_SEASON_BY_ID_SUCCESS = 'DELETE_SEASON_BY_ID_SUCCESS';
export const DELETE_SEASON_BY_ID_ERROR = 'DELETE_SEASON_BY_ID_ERROR';
export const DELETE_SEASON_BY_ID_LOADING = 'DELETE_SEASON_BY_ID_LOADING';
export const UPDATE_ORGANIZATION_LOADING = 'UPDATE_ORGANIZATION_LOADING';
export const UPDATE_ORGANIZATION_SUCCESS = 'UPDATE_ORGANIZATION_SUCCESS';
export const UPDATE_ORGANIZATION_ERROR = 'UPDATE_ORGANIZATION_ERROR';
export const DELETE_ORGANIZATION_LOADING = 'DELETE_ORGANIZATION_LOADING';
export const DELETE_ORGANIZATION_SUCCESS = 'DELETE_ORGANIZATION_SUCCESS';
export const DELETE_ORGANIZATION_ERROR = 'DELETE_ORGANIZATION_ERROR';
export const DELETE_ORGANIZATION_RESET = 'DELETE_ORGANIZATION_RESET';
export const UPLOAD_ORGANIZATION_AVATAR_LOADING = 'UPLOAD_ORGANIZATION_AVATAR_LOADING';
export const UPLOAD_ORGANIZATION_AVATAR_SUCCESS = 'UPLOAD_ORGANIZATION_AVATAR_SUCCESS';
export const UPLOAD_ORGANIZATION_AVATAR_ERROR = 'UPLOAD_ORGANIZATION_AVATAR_ERROR';
export const REMOVE_ORGANIZATION_AVATAR_LOADING = 'REMOVE_ORGANIZATION_AVATAR_LOADING';
export const REMOVE_ORGANIZATION_AVATAR_SUCCESS = 'REMOVE_ORGANIZATION_AVATAR_SUCCESS';
export const REMOVE_ORGANIZATION_AVATAR_ERROR = 'REMOVE_ORGANIZATION_AVATAR_ERROR';
export const FETCH_CROP_DETAILS_SUCCESS = 'FETCH_CROP_DETAILS_SUCCESS';
export const FETCH_CROP_DETAILS_LOADING = 'FETCH_CROP_DETAILS_LOADING';
export const FETCH_CROP_DETAILS_ERROR = 'FETCH_CROP_DETAILS_ERROR';
export const CREATE_SEASON_LOADING = 'CREATE_SEASON_LOADING';
export const CREATE_SEASON_SUCCESS = 'CREATE_SEASON_SUCCESS';
export const CREATE_SEASON_ERROR = 'CREATE_SEASON_ERROR';
export const RESET_CREATE_SEASON_FLAG = 'RESET_CREATE_SEASON_FLAG';
export const RESET_EDIT_SEASON_FLAG = 'RESET_EDIT_SEASON_FLAG';
export const EDIT_SEASON_LOADING = 'EDIT_SEASON_LOADING';
export const EDIT_SEASON_SUCCESS = 'EDIT_SEASON_SUCCESS';
export const EDIT_SEASON_ERROR = 'EDIT_SEASON_ERROR';
export const ADD_MANAGED_USER_LOADING = 'ADD_MANAGED_USER_LOADING';
export const ADD_MANAGED_USER_SUCCESS = 'ADD_MANAGED_USER_SUCCESS';
export const ADD_MANAGED_USER_ERROR = 'ADD_MANAGED_USER_ERROR';
export const GET_USER_DETAILS_LOADING = 'GET_USER_DETAILS_LOADING';
export const GET_USER_DETAILS_SUCCESS = 'GET_USER_DETAILS_SUCCESS';
export const GET_USER_DETAILS_ERROR = 'GET_USER_DETAILS_ERROR';

export const GET_USER_DETAILS_BY_STATUS_LOADING = 'GET_USER_DETAILS_BY_STATUS_LOADING';
export const GET_USER_DETAILS_BY_STATUS_SUCCESS = 'GET_USER_DETAILS_BY_STATUS_SUCCESS';
export const GET_USER_DETAILS_BY_STATUS_ERROR = 'GET_USER_DETAILS_BY_STATUS_ERROR';

export const INVITE_USER_LOADING = 'INVITE_USER_LOADING';
export const INVITE_USER_SUCCESS = 'INVITE_USER_SUCCESS';
export const INVITE_USER_ERROR = 'INVITE_USER_ERROR';
export const EDIT_USER_PERMISSION_LOADING = 'EDIT_USER_PERMISSION_LOADING';
export const EDIT_USER_PERMISSION_SUCCESS = 'EDIT_USER_PERMISSION_SUCCESS';
export const EDIT_USER_PERMISSION_ERROR = 'EDIT_USER_PERMISSION_ERROR';
export const EDIT_USER_DETAILS_LOADING = 'EDIT_USER_DETAILS_LOADING';
export const EDIT_USER_DETAILS_SUCCESS = 'EDIT_USER_DETAILS_SUCCESS';
export const EDIT_USER_DETAILS_ERROR = 'EDIT_USER_DETAILS_ERROR';
export const REMOVE_USER_LOADING = 'REMOVE_USER_LOADING';
export const REMOVE_USER_SUCCESS = 'REMOVE_USER_SUCCESS';
export const REMOVE_USER_ERROR = 'REMOVE_USER_ERROR';
export const FETCH_VARIETIES_LOADING = 'FETCH_VARIETIES_LOADING';
export const FETCH_VARIETIES_SUCCESS = 'FETCH_VARIETIES_SUCCESS';
export const FETCH_VARIETIES_ERROR = 'FETCH_VARIETIES_ERROR';
export const FETCH_SEASON_BY_ID_LOADING = 'FETCH_SEASON_BY_ID_LOADING';
export const FETCH_SEASON_BY_ID_SUCCESS = 'FETCH_SEASON_BY_ID_SUCCESS';
export const FETCH_SEASON_BY_ID_ERROR = 'FETCH_SEASON_BY_ID_ERROR';
export const EDIT_ORG_LOADING = 'EDIT_ORG_LOADING';
export const EDIT_ORG_SUCCESS = 'EDIT_ORG_SUCCESS';
export const EDIT_ORG_ERROR = 'EDIT_ORG_ERROR';
export const FETCH_ALL_FIELDS_BY_ORG_LOADING = 'FETCH_ALL_FIELDS_BY_ORG_LOADING';
export const FETCH_ALL_FIELDS_BY_ORG_ERROR = 'FETCH_ALL_FIELDS_BY_ORG_ERROR';
export const FETCH_FIELDS_BY_ID_LOADING = 'FETCH_FIELDS_BY_ID_LOADING';
export const FETCH_FIELDS_BY_ID_SUCCESS = 'FETCH_FIELDS_BY_ID_SUCCESS';
export const FETCH_FIELDS_BY_ID_ERROR = 'FETCH_FIELDS_BY_ID_ERROR';
export const RESET_ALL_FIELDS = 'RESET_ALL_FIELDS';
export const UPDATE_EXISTING_PROPERTY_IDS = 'UPDATE_EXISTING_PROPERTY_IDS';
export const FETCH_ALL_FIELDS_BY_ORG_SUCCESS = 'FETCH_ALL_FIELDS_BY_ORG_SUCCESS';
export const UPDATE_PROFILE_SETTINGS_LOADING = 'UPDATE_PROFILE_SETTINGS_LOADING';
export const UPDATE_PROFILE_SETTINGS_SUCCESS = 'UPDATE_PROFILE_SETTINGS_SUCCESS';
export const UPDATE_PROFILE_SETTINGS_ERROR = 'UPDATE_PROFILE_SETTINGS_ERROR';

export const FETCH_WEATHER_DATA_SUCCESS = 'FETCH_WEATHER_DATA_SUCCESS';
export const FETCH_WEATHER_DATA_LOADING = 'FETCH_WEATHER_DATA_LOADING';
export const FETCH_WEATHER_DATA_ERROR = 'FETCH_WEATHER_DATA_ERROR';
export const FETCH_DAILY_OBSERVATIONS_LOADING = 'FETCH_DAILY_OBSERVATIONS_LOADING';
export const FETCH_DAILY_OBSERVATIONS_SUCCESS = 'FETCH_DAILY_OBSERVATIONS_SUCCESS';
export const FETCH_DAILY_OBSERVATIONS_ERROR = 'FETCH_DAILY_OBSERVATIONS_ERROR';
export const FETCH_HOURLY_OBSERVATIONS_LOADING = 'FETCH_HOURLY_OBSERVATIONS_LOADING';
export const FETCH_HOURLY_OBSERVATIONS_SUCCESS = 'FETCH_HOURLY_OBSERVATIONS_SUCCESS';
export const FETCH_HOURLY_OBSERVATIONS_ERROR = 'FETCH_HOURLY_OBSERVATIONS_ERROR';

export const FETCH_DAILY_FORECAST_LOADING = 'FETCH_DAILY_FORECAST_LOADING';
export const FETCH_DAILY_FORECAST_SUCCESS = 'FETCH_DAILY_FORECAST_SUCCESS';
export const FETCH_DAILY_FORECAST_ERROR = 'FETCH_DAILY_FORECAST_ERROR';
export const FETCH_HOURLY_FORECAST_LOADING = 'FETCH_HOURLY_FORECAST_LOADING';
export const FETCH_HOURLY_FORECAST_SUCCESS = 'FETCH_HOURLY_FORECAST_SUCCESS';
export const FETCH_HOURLY_FORECAST_ERROR = 'FETCH_HOURLY_FORECAST_ERROR';
export const FETCH_SPRAY_RECOMMENDATIONS_LOADING = 'FETCH_SPRAY_RECOMMENDATIONS_LOADING';
export const FETCH_SPRAY_RECOMMENDATIONS_SUCCESS = 'FETCH_SPRAY_RECOMMENDATIONS_SUCCESS';
export const FETCH_SPRAY_RECOMMENDATIONS_ERROR = 'FETCH_SPRAY_RECOMMENDATIONS_ERROR';

export const FETCH_PROPERTIES_BY_SEASONS_LOADING = 'FETCH_PROPERTIES_BY_SEASONS_LOADING';
export const FETCH_PROPERTIES_BY_SEASONS_SUCCESS = 'FETCH_PROPERTIES_BY_SEASONS_SUCCESS';
export const FETCH_PROPERTIES_BY_SEASONS_ERROR = 'FETCH_PROPERTIES_BY_SEASONS_ERROR';
export const RESET_ADD_PROPERTIES_BY_SEASONS = 'RESET_ADD_PROPERTIES_BY_SEASONS';
export const ADD_PROPERTIES_BY_SEASONS_LOADING = 'ADD_PROPERTIES_BY_SEASONS_LOADING';
export const ADD_PROPERTIES_BY_SEASONS_SUCCESS = 'ADD_PROPERTIES_BY_SEASONS_SUCCESS';
export const ADD_PROPERTIES_BY_SEASONS_ERROR = 'ADD_PROPERTIES_BY_SEASONS_ERROR';
export const DELETE_PROPERTIES_BY_SEASONS = 'DELETE_PROPERTIES_BY_SEASONS';

export const FETCH_APPS_LOADING = 'FETCH_APPS_LOADING';
export const FETCH_APPS_SUCCESS = 'FETCH_APPS_SUCCESS';
export const FETCH_APPS_ERROR = 'FETCH_APPS_ERROR';
export const RESET_FETCH_APPS = 'RESET_FETCH_APPS';

export const FETCH_TASKS_ERROR = 'FETCH_TASKS_ERROR';
export const FETCH_TASKS_SUCCESS = 'FETCH_TASKS_SUCCESS';
export const FETCH_TASKS_LOADING = 'FETCH_TASKS_LOADING';
export const DELETE_TASK_LOADING = 'DELETE_TASK_LOADING';
export const DELETE_TASK_SUCCESS = 'DELETE_TASK_SUCCESS';
export const DELETE_TASK_ERROR = 'DELETE_TASK_ERROR';
export const ADD_NEW_TASK_LOADING = 'ADD_NEW_TASK_LOADING';
export const ADD_NEW_TASK_SUCCESS = 'ADD_NEW_TASK_SUCCESS';
export const ADD_NEW_TASK_ERROR = 'ADD_NEW_TASK_ERROR';
export const UPDATE_TASK_LOADING = 'UPDATE_TASK_LOADING';
export const UPDATE_TASK_SUCCESS = 'UPDATE_TASK_SUCCESS';
export const UPDATE_TASK_ERROR = 'UPDATE_TASK_ERROR';

export const FETCH_TASKS_CATELOGS_LOADING = 'FETCH_TASKS_CATELOGS_LOADING';
export const FETCH_TASKS_CATELOGS_SUCCESS = 'FETCH_TASKS_CATELOGS_SUCCESS';
export const FETCH_TASKS_CATELOGS_ERROR = 'FETCH_TASKS_CATELOGS_ERROR';

export const RESET_CREATE_UPDATE_TASK_STORE = 'RESET_CREATE_UPDATE_TASK_STORE';

export const FETCH_EQUIPMENTS_DATA_LOADING = 'FETCH_EQUIPMENTS_DATA_LOADING';
export const FETCH_EQUIPMENTS_DATA_SUCCESS = 'FETCH_EQUIPMENTS_DATA_SUCCESS';
export const FETCH_EQUIPMENTS_DATA_ERROR = 'FETCH_EQUIPMENTS_DATA_ERROR';

export const FETCH_DETECT_FIELDS_BOUNDRIES_LOADING = 'FETCH_DETECT_FIELDS_BOUNDRIES_LOADING';
export const FETCH_DETECT_FIELDS_BOUNDRIES_SUCCESS = 'FETCH_DETECT_FIELDS_BOUNDRIES_SUCCESS';
export const FETCH_DETECT_FIELDS_BOUNDRIES_ERROR = 'FETCH_DETECT_FIELDS_BOUNDRIES_ERROR';

export const ADD_EQUIPMENT_LOADING = 'UPDATE_TABLE_LOADING';
export const ADD_EQUIPMENT_SUCCESS = 'UPDATE_TABLE_SUCCESS';
export const ADD_EQUIPMENT_ERROR = 'UPDATE_TABLE_ERROR';
export const EDIT_EQUIPMENT_LOADING = 'EDIT_EQUIPMENT_LOADING';
export const EDIT_EQUIPMENT_SUCCESS = 'EDIT_EQUIPMENT_SUCCESS';
export const EDIT_EQUIPMENT_ERROR = 'EDIT_EQUIPMENT_ERROR';
export const DELETE_EQUIPMENT_LOADING = 'DELETE_EQUIPMENT_LOADING';
export const DELETE_EQUIPMENT_SUCCESS = 'DELETE_EQUIPMENT_SUCCESS';
export const DELETE_EQUIPMENT_ERROR = 'DELETE_EQUIPMENT_ERROR';
export const RESET_EQUIPMENT_STORE = 'RESET_EQUIPMENT_STORE';

export const FETCH_EXECUTERS_DATA_LOADING = 'FETCH_EXECUTERS_DATA_LOADING';
export const FETCH_EXECUTERS_DATA_SUCCESS = 'FETCH_EXECUTERS_DATA_SUCCESS';
export const FETCH_EXECUTERS_DATA_ERROR = 'FETCH_EXECUTERS_DATA_ERROR';
export const ADD_EXECUTERS_LOADING = 'ADD_EXECUTERS_LOADING';
export const ADD_EXECUTERS_SUCCESS = 'ADD_EXECUTERS_SUCCESS';
export const ADD_EXECUTERS_ERROR = 'ADD_EXECUTERS_ERROR';
export const EDIT_EXECUTERS_LOADING = 'EDIT_EXECUTERS_LOADING';
export const EDIT_EXECUTERS_SUCCESS = 'EDIT_EXECUTERS_SUCCESS';
export const EDIT_EXECUTERS_ERROR = 'EDIT_EXECUTERS_ERROR';
export const DELETE_EXECUTERS_LOADING = 'DELETE_EXECUTERS_LOADING';
export const DELETE_EXECUTERS_SUCCESS = 'DELETE_EXECUTERS_SUCCESS';
export const DELETE_EXECUTERS_ERROR = 'DELETE_EXECUTERS_ERROR';
export const RESET_EXECUTER_STORE = 'RESET_EXECUTER_STORE';

export const FETCH_PRODUCTS_DATA_LOADING = 'FETCH_PRODUCTS_DATA_LOADING';
export const FETCH_PRODUCTS_DATA_SUCCESS = 'FETCH_PRODUCTS_DATA_SUCCESS';
export const FETCH_PRODUCTS_DATA_ERROR = 'FETCH_PRODUCTS_DATA_ERROR';
export const ADD_PRODUCTS_LOADING = 'ADD_PRODUCTS_LOADING';
export const ADD_PRODUCTS_SUCCESS = 'ADD_PRODUCTS_SUCCESS';
export const ADD_PRODUCTS_ERROR = 'ADD_PRODUCTS_ERROR';
export const EDIT_PRODUCTS_LOADING = 'EDIT_PRODUCTS_LOADING';
export const EDIT_PRODUCTS_SUCCESS = 'EDIT_PRODUCTS_SUCCESS';
export const EDIT_PRODUCTS_ERROR = 'EDIT_PRODUCTS_ERROR';
export const DELETE_PRODUCTS_LOADING = 'DELETE_PRODUCTS_LOADING';
export const DELETE_PRODUCTS_SUCCESS = 'DELETE_PRODUCTS_SUCCESS';
export const DELETE_PRODUCTS_ERROR = 'DELETE_PRODUCTS_ERROR';
export const RESET_PRODUCT_STORE = 'RESET_PRODUCT_STORE';

export const GET_TERMS_CONDITIONS_LOADING = 'GET_TERMS_CONDITIONS_LOADING';
export const GET_TERMS_CONDITIONS_SUCCESS = 'GET_TERMS_CONDITIONS_SUCCESS';
export const GET_TERMS_CONDITIONS_ERROR = 'GET_TERMS_CONDITIONS_ERROR';
export const TERMS_CONDITIONS_RESET = 'TERMS_CONDITIONS_RESET';

export const GET_LICENSING_STATUS_LOADING = 'GET_LICENSING_STATUS_LOADING';
export const GET_LICENSING_STATUS_SUCCESS = 'GET_LICENSING_STATUS_SUCCESS';
export const GET_LICENSING_STATUS_ERROR = 'GET_LICENSING_STATUS_ERROR';
export const GET_LICENSING_STATUS_RESET = 'GET_LICENSING_STATUS_RESET';

export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_REQUEST_SUCCESS = 'USER_LOGIN_REQUEST_SUCCESS';
export const USER_LOGIN_REQUEST_ERROR = 'USER_LOGIN_REQUEST_ERROR';

export const SET_ACTIVE_SEASON_BY_ID_SUCCESS = ' SET_ACTIVE_SEASON_BY_ID_SUCCESS';
export const SET_ACTIVE_SEASON_BY_ID_LOADING = '  SET_ACTIVE_SEASON_BY_ID_LOADING';
export const SET_ACTIVE_SEASON_BY_ID_ERROR = '  SET_ACTIVE_SEASON_BY_ID_ERROR';

export const SET_REGULATORY_ZONE_LOADING = 'SET_REGULATORY_ZONE_LOADING';
export const SET_REGULATORY_ZONE_SUCCESS = 'SET_REGULATORY_ZONE_SUCCESS';
export const SET_REGULATORY_ZONE_ERROR = 'SET_REGULATORY_ZONE_ERROR';

export const SET_NAVBAR = 'SET_NAVBAR';
export const FETCH_ALL_FIELDS_BY_PROP_ID_SUCCESS = 'FETCH_ALL_FIELDS_BY_PROP_ID_SUCCESS';
export const FETCH_ALL_FIELDS_BY_PROP_ID_ERROR = 'FETCH_ALL_FIELDS_BY_PROP_ID_ERROR';
export const FETCH_ALL_FIELDS_BY_PROP_ID_LOADING = 'FETCH_ALL_FIELDS_BY_PROP_ID_LOADING';

export const FETCH_SEASONS_BY_PROP_ID_SUCCESS = 'FETCH_SEASONS_BY_PROP_ID_SUCCESS';
export const FETCH_SEASONS_BY_PROP_ID_ERROR = 'FETCH_SEASONS_BY_PROP_ID_ERROR';
export const FETCH_SEASONS_BY_PROP_ID_LOADING = 'FETCH_SEASONS_BY_PROP_ID_LOADING';

export const CREATE_REGION_SUCCESS = 'CREATE_REGION_SUCCESS';
export const CREATE_REGION_ERROR = 'CREATE_REGION_ERROR';
export const REGION_LOADING = 'REGION_LOADING';
export const GET_REGION_TREE_BY_REGION_ID_LOADING = 'GET_REGION_TREE_BY_REGION_ID_LOADING';
export const GET_REGION_TREE_BY_REGION_ID_SUCCESS = 'GET_REGION_TREE_BY_REGION_ID_SUCCESS';
export const GET_REGION_TREE_BY_REGION_ID__ERROR = 'GET_REGION_TREE_BY_REGION_ID__ERROR';
export const FETCH_REGIONS_BY_PROPERTY_ID_LOADING = 'FETCH_REGIONS_BY_PROPERTY_ID_LOADING';
export const FETCH_REGIONS_BY_PROPERTY_ID_SUCCESS = 'FETCH_REGIONS_BY_PROPERTY_ID_SUCCESS';
export const FETCH_REGIONS_BY_PROPERTY_ID_ERROR = 'FETCH_REGIONS_BY_PROPERTY_ID_ERROR';
export const RESET_REGION_LIST_DATA = 'RESET_REGION_LIST_DATA';

export const SETUP_ROLLOUT = 'SETUP_ROLLOUT';
export const SETUP_ROLLOUT_SUCCESS = 'SETUP_ROLLOUT_SUCCESS';
export const SETUP_ROLLOUT_ERROR = 'SETUP_ROLLOUT_ERROR';

export const GET_VOUCHER_REDEEM_INFO_LOADING = 'GET_VOUCHER_REDEEM_INFO_LOADING';
export const GET_VOUCHER_REDEEM_INFO_SUCCESS = 'GET_VOUCHER_REDEEM_INFO_SUCCESS';
export const GET_VOUCHER_REDEEM_INFO_ERROR = 'GET_VOUCHER_REDEEM_INFO_ERROR';

export const REDEEM_VOUCHER_LOADING = 'REDEEM_VOUCHER_LOADING';
export const REDEEM_VOUCHER_SUCCESS = 'REDEEM_VOUCHER_SUCCESS';
export const REDEEM_VOUCHER_ERROR = 'REDEEM_VOUCHER_ERROR';

export const FETCH_FIELD_DETECTION_SOURCE_LIST_LOADING =
  'FETCH_FIELD_DETECTION_SOURCE_LIST_LOADING';
export const FETCH_FIELD_DETECTION_SOURCE_LIST_ERROR = 'FETCH_FIELD_DETECTION_SOURCE_LIST_ERROR';
export const FETCH_FIELD_DETECTION_SOURCE_LIST_SUCCESS =
  'FETCH_FIELD_DETECTION_SOURCE_LIST_SUCCESS';

export const FETCH_ALL_FIELDS_HISTORY_BY_PROP_ID_SUCCESS =
  'FETCH_ALL_FIELDS_HISTORY_BY_PROP_ID_SUCCESS';
export const FETCH_ALL_FIELDS_HISTORY_BY_PROP_ID_ERROR =
  'FETCH_ALL_FIELDS_HISTORY_BY_PROP_ID_ERROR';
export const FETCH_ALL_FIELDS_HISTORY_BY_PROP_ID_LOADING =
  'FETCH_ALL_FIELDS_HISTORY_BY_PROP_ID_LOADING';

export const CREATE_BILLING_INTENT_LOADING = 'CREATE_BILLING_INTENT_LOADING';
export const CREATE_BILLING_INTENT_SUCCESS = 'CREATE_BILLING_INTENT_SUCCESS';
export const CREATE_BILLING_INTENT_ERROR = 'CREATE_BILLING_INTENT_ERROR';

export const SET_WHATS_NEW_ISVISIBLE = 'SET_WHATS_NEW_ISVISIBLE';
export const HAVE_CONTRACT_FOR_ORG = 'HAVE_CONTRACT_FOR_ORG';

export const FETCH_FIELD_HISTORY_DETAILS_BY_ID_LOADING =
  'FETCH_FIELD_HISTORY_DETAILS_BY_ID_LOADING';
export const FETCH_FIELD_HISTORY_DETAILS_BY_ID_SUCCESS =
  'FETCH_FIELD_HISTORY_DETAILS_BY_ID_SUCCESS';
export const FETCH_FIELD_HISTORY_DETAILS_BY_ID_ERROR = 'FETCH_FIELD_HISTORY_DETAILS_BY_ID_ERROR';
