/**
 * App Loader : Before Loading App in the dom, we need to load few meta data in our system.
 * App Loader will be used to control the sequence in which things are loaded.
 */
import { initOAuth2Flow, isAccessTokenAvailable } from '../utils/common-methods';
import { history, publicItems } from '../utils/history';

class AppLoader {
  constructor() {
    this.metaData = {};
  }
  getMetaData() {
    return this.metaData;
  }

  async redirectAsPerLoggedInStatus() {
    if (!isAccessTokenAvailable() && !publicItems.includes(history.location.pathname)) {
      let returnStateValue = history.location.pathname;
      if (history.location.search.length > 0) {
        returnStateValue = `${returnStateValue}${history.location.search}`;
      }
      initOAuth2Flow(returnStateValue);
      return true;
    }
    return false;
  }
  async load() {
    const redirectStatus = await this.redirectAsPerLoggedInStatus();
    if (redirectStatus) {
      return redirectStatus;
    }
    return false;
  }
}

export { AppLoader };
