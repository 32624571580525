/* eslint-disable */

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import { CookieStorage } from 'cookie-storage';
import jwtDecode from 'jwt-decode';
import first from 'lodash/first';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import last from 'lodash/last';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import React from 'react';
import { DEFAULT_LATLONG } from '../components/landingFarm/AutoFillHandler';
import { AppConstants } from '../utils/app.constants';
import { getErrorMessageByCode } from './getErrorMessageByCode';
import { getLatLongByPropId } from './getLatLongByPropId';
import { iff } from './iff';
import { getLocationFromBrowser } from './location';

const cookieStorage = new CookieStorage();
const qaLinkConstant = 'qa.accounts.cropwise.com';

export const isAccessTokenAvailable = () => !!localStorage.getItem('tokens');
export const setAccessToken = (token) => localStorage.setItem('tokens', token);
export const getAccessToken = () => localStorage.getItem('tokens') || {};
export const clearAccessToken = () => localStorage.removeItem('tokens');

//set moment object timezone to the passed timezone, if not passed set to local timezone as default
export const setMomentDateTimeZone = (
  timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
) => {
  momentTimezone.tz.setDefault(timeZone);
};

/**
 * @param {*}
 * date -> it can be date js object or normal date string
 * timezone -> timezone(property timezone)
 * @returns converted moment date object with specified timezone
 */
export const getDateWithPassedTimeZone = (
  date,
  timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
) => {
  if (date) {
    return momentTimezone(date).tz(timeZone);
  } else {
    return null;
  }
};

export const getDomain = () =>
  document.location.hostname.includes('cropwise.com') ? 'cropwise.com' : 'syngentadigital.co.uk';

export const cleanNewUserSession = () => {
  const env = process.env.REACT_APP_envName;
  const key = `resetUserSession_${env}`;
  if (cookieStorage.getItem(key)) {
  }
};

export const setLocalUsingCookies = (lang) => {
  const env = process.env.REACT_APP_envName;
  if (env) {
    const key = `i18nextLng_${env}`;
    const domain = getDomain();
    cookieStorage.setItem(key, lang, {
      path: '/',
      domain
    });
  }
};

export const getLocaleUsingCookies = () => {
  const env = process.env.REACT_APP_envName;
  const key = `i18nextLng_${env}`;
  return cookieStorage.getItem(key);
};

export const setLocaleTempUsingCookies = (lang) => {
  const env = process.env.REACT_APP_envName;
  if (env) {
    const key = `i18nextLngTemp_${env}`;
    const domain = getDomain();
    cookieStorage.setItem(key, lang, {
      path: '/',
      domain
    });
  }
};

export const getLocaleTempUsingCookies = () => {
  const env = process.env.REACT_APP_envName;
  const key = `i18nextLngTemp_${env}`;
  return cookieStorage.getItem(key);
};

export const tokenBelongsToAuthority = (authorizationToken, authority) => {
  try {
    const decoded = jwtDecode(authorizationToken);
    return (decoded.authorities || []).indexOf(authority) > -1;
  } catch (err) {
    return false;
  }
};

export const isUserSupportOrSuperUser = () => {
  try {
    const token = JSON.parse(getAccessToken());
    return (
      tokenBelongsToAuthority(token.access_token, 'SUPER_USER') ||
      tokenBelongsToAuthority(token.access_token, 'SUPPORT')
    );
  } catch (err) {
    return false;
  }
};

export const getProgressByValues = (isPasswordValid, isMobileNumberValid, termsCheckbox) => {
  let count = 1;
  if (isPasswordValid) {
    count++;
  }
  if (isMobileNumberValid) {
    count++;
  }
  if (termsCheckbox) {
    count++;
  }
  return count;
};

export const isEmptyStr = (str) => {
  if (typeof str === 'string' && str.length > 0) {
    return true;
  }
  return false;
};

export const getHeaders = () => ({
  common: {
    Authorization: `Bearer ${JSON.parse(getAccessToken()).access_token}`
  }
});

export const getLangHeaders = (overrideLanguage) => {
  let lang = getLocaleUsingCookies() || localStorage.getItem('i18nextLng') || 'en';
  if (lang === 'pt') {
    lang = 'pt-BR';
  }
  return {
    common: {
      Authorization: `Bearer ${JSON.parse(getAccessToken()).access_token}`,
      'Accept-Language': overrideLanguage || lang
    }
  };
};

export const getInitialName = (name) => {
  if (isEmpty(name)) {
    return '';
  }
  const splitedNames = name.split(' ');
  const firstName = first(splitedNames) || '';
  const lastName = last(splitedNames) || '';
  if (splitedNames.length >= 2) {
    return `${firstName.charAt(0).toUpperCase()} ${lastName.charAt(0).toUpperCase()}`;
  }
  return firstName.charAt(0).toUpperCase();
};

export const getShortName = (name) => {
  if (isEmpty(name)) {
    return '';
  }
  const splitedNames = name.split(' ');
  const firstName = first(splitedNames) || '';
  const lastName = last(splitedNames) || '';
  if (splitedNames.length >= 2) {
    return `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`;
  }
  return firstName.charAt(0).toUpperCase();
};

export const setTimeoutPromise = async (time) =>
  new Promise((resolve) => setTimeout(resolve, time));

export const isSearchSubStringFound = (searchString = '', fields = []) =>
  !isEmpty(
    fields.filter((field) => (field || '').toLowerCase().indexOf(searchString.toLowerCase()) >= 0)
  );

export const CommaSeparatedList = (a, b) => {
  try {
    if (!b) {
      return a.join(', ');
    }
    return get(a, b).join(', ');
  } catch (e) {
    // do nothing
    return '';
  }
};

export const getSecondaryStatus = (status, startDate, endDate) => {
  if (
    (startDate &&
      status === 'Planned' &&
      moment().diff(moment(startDate).format('YYYY-MM-DD'), 'days') > 0) ||
    (endDate &&
      status === 'In progress' &&
      moment().diff(moment(endDate).format('YYYY-MM-DD'), 'days') > 0)
  ) {
    return 'DELAYED';
  }
  return '';
};

export const getAppHostsInfo = () => {
  const domainMapping = {
    'localhost:3000': { host: 'GLOBAL', account: qaLinkConstant },
    'localhost:4000': { host: 'UK', account: 'uk-qa.accounts.cropwise.com' },
    'localhost:5000': { host: 'UK', account: 'qa.accounts.syngentadigital.co.uk' },
    'beta.app.cropwise.com': { host: 'GLOBAL', account: 'beta.accounts.cropwise.com' },
    'beta.app.syngentadigital.co.uk': {
      host: 'UK',
      account: 'beta.accounts.syngentadigital.co.uk'
    },
    'dev.app.cropwise.com': { host: 'GLOBAL', account: 'dev.accounts.cropwise.com' },
    'uk-dev.app.cropwise.com': { host: 'UK', account: 'uk-dev.accounts.cropwise.com' },
    'dev.app.syngentadigital.co.uk': { host: 'UK', account: 'dev.accounts.syngentadigital.co.uk' },
    'qa.app.cropwise.com': { host: 'GLOBAL', account: qaLinkConstant },
    'staff.qa.cropwise.com': { host: 'GLOBAL', account: qaLinkConstant },
    'uk-qa.app.cropwise.com': { host: 'UK', account: 'uk-qa.accounts.cropwise.com' },
    'qa.app.syngentadigital.co.uk': { host: 'UK', account: 'qa.accounts.syngentadigital.co.uk' },
    'staging.app.cropwise.com': { host: 'GLOBAL', account: 'staging.accounts.cropwise.com' },
    'uk-staging.app.cropwise.com': { host: 'UK', account: 'uk-staging.accounts.cropwise.com' },
    'staging.app.syngentadigital.co.uk': {
      host: 'UK',
      account: 'staging.accounts.syngentadigital.co.uk'
    },
    'app.cropwise.com': { host: 'GLOBAL', account: 'accounts.cropwise.com' },
    'uk.app.cropwise.com': { host: 'UK', account: 'uk.accounts.cropwise.com' },
    'app.syngentadigital.co.uk': { host: 'UK', account: 'accounts.syngentadigital.co.uk' }
  };

  const { host } = window.location;
  return {
    host: (!isEmpty(domainMapping[host]) && domainMapping[host].host) || 'GLOBAL',
    account: !isEmpty(domainMapping[host]) && domainMapping[host].account,
    hostsList: [...new Set(Object.values(domainMapping).map((d) => d.host))]
  };
};

export const isUK = () => {
  const { host } = getAppHostsInfo();
  if (host === 'UK') {
    return true;
  }
  return false;
};

export const filterTextForBrandName = (content) => {
  try {
    if (isUK()) {
      return content.replace(/Cropwise/g, '').replace(/cropwise/g, '');
    }
  } catch (e) {
    console.log(e);
  }
  return content;
};

export const getUrl = (baseUrl, queryParams) => {
  if (queryParams && Object.keys(queryParams).length > 0) {
    let delimiter, paramValue;
    let isFirstParam = false;
    const urlWithQueryParams = Object.keys(queryParams).reduce((parameterString, key) => {
      const paramKey = encodeURIComponent(key);
      paramValue = encodeURIComponent(queryParams[key]);
      if (paramValue && !isFirstParam) {
        delimiter = '?';
        isFirstParam = true;
      } else {
        delimiter = '&';
      }

      if (paramValue || typeof val === 'number') {
        return [parameterString, delimiter, paramKey, '=', paramValue].join('');
      } else {
        return parameterString;
      }
    }, baseUrl);
    return urlWithQueryParams;
  } else {
    return baseUrl;
  }
};

export const getSorter = (sorter) => {
  let sort = '';
  if (Object.keys(sorter).length > 0 && sorter.order) {
    if (sorter.order === 'descend') {
      sort = `${sorter.field}${','}${sorter.order.slice(0, 4)}`;
    } else {
      sort = `${sorter.field}${','}${sorter.order.slice(0, 3)}`;
    }
  }
  return sort;
};

export const initOAuth2Flow = (returnStateValue) => {
  const returnState = returnStateValue ? `&state=${btoa(returnStateValue)}` : '';
  const { account } = getAppHostsInfo();
  const { O_AUTH } = AppConstants;
  window.location.href = `https://${account}/oauth/authorize?response_type=${O_AUTH.responseCode}&client_id=${O_AUTH.clientId}&redirect_uri=${location.origin}/authenticate${returnState}`;
};

export const getAccountsOAuthUrl = () => {
  const { account } = getAppHostsInfo();
  const { O_AUTH } = AppConstants;
  return `https://${account}/oauth/authorize?response_type=${O_AUTH.responseCode}&client_id=${O_AUTH.clientId}&redirect_uri=${location.origin}/authenticate`;
};

export const getAccountsTnCUrl = () => {
  const { account } = getAppHostsInfo();
  return `https://${account}/authenticate?referrer_url=${location.origin}&redirect_path=terms-and-conditions`;
};

export const getAccountsVoucherRedeemUrl = () => {
  const { account } = getAppHostsInfo();
  return `https://${account}/authenticate?referrer_url=${location.origin}&redirect_path=voucher-redeem`;
};

export const getLocale = () => {
  let lang = getLocaleUsingCookies() || 'en';
  if (lang === 'pt') {
    lang = 'pt-BR';
  }
  return lang;
};

export const localeForCatalog = (queryStringPrefix) => {
  let location = '@BR';
  if (localStorage.getItem('localisedCatalogOff')) {
    if (queryStringPrefix) {
      return '';
    }
    return {};
  }
  if (isUK()) {
    location = '@GB';
  }
  if (queryStringPrefix) {
    return `${queryStringPrefix}location=${location}`;
  }
  return { location };
};

export const getRowsAccordingToWindowHeight = (lessRows = 0) =>
  Math.floor(
    iff(
      window.innerHeight < 800,
      window.innerHeight,
      window.innerHeight + (window.innerHeight - 800)
    ) / 100
  ) - lessRows;

export const sortByName = (sortArray) => {
  sortArray.sort(function (a, b) {
    const x = a.name.toLowerCase();
    const y = b.name.toLowerCase();
    if (x < y) {
      return -1;
    }
    if (x > y) {
      return 1;
    }
    return 0;
  });
};

export const getLanguageCode = (lang) => {
  const selectedLang = lang || navigator.language;
  const langKeys = AppConstants.SUPPORTED_LANGS.map((lang) => lang.value);
  if (langKeys.includes(lang)) {
    return lang;
  }
  return langKeys.find((langKey) => selectedLang.includes(langKey)) || 'en';
};

export const sleep = (milliseconds) => {
  const date = Date.now();
  let currentDate = null;
  do {
    currentDate = Date.now();
  } while (currentDate - date < milliseconds);
};

export const isValidCallbackURI = (callbackUri) => {
  return +callbackUri.split('?')[0].split('://').length === 2;
};

// get random number from 0 to maxLimit
export const getRandomValue = (maxLimit = 10) => {
  const crypto = window.crypto || window.msCrypto;
  var array = new Uint32Array(1);
  crypto.getRandomValues(array);
  return array[0] % maxLimit;
};

export const productsURLForProdEnv = (currentWorkspace) => {
  return isUK()
    ? `https://accounts.syngentadigital.co.uk/app/workspaces/${currentWorkspace}/products`
    : `https://accounts.cropwise.com/app/workspaces/${currentWorkspace}/products`;
};

export const productsURLForOtherEnvs = (currentWorkspace, currentEnv) => {
  return isUK()
    ? `https://${currentEnv}.accounts.syngentadigital.co.uk/app/workspaces/${currentWorkspace}/products`
    : `https://${currentEnv}.accounts.cropwise.com/app/workspaces/${currentWorkspace}/products`;
};

export const openWarningNotification = (message, description) => {
  notification.open({
    message: message,
    duration: 0,
    description: description,
    icon: <ExclamationCircleOutlined style={{ color: '#CF3537' }} />
  });
};

export const getSearchParam = (locationSearch, param) => {
  return new URLSearchParams(locationSearch).get(param) || '';
};

// locate client browser address and set if we are passing setstate method otherwise just return it
export const setFocusBoundsThroughLocatingBrowserAddress = async (setFocusBounds) => {
  const location = await getLocationFromBrowser();
  const latLongs = [location.latitude, location.longitude];
  const bounds = L.latLngBounds(latLongs, latLongs);
  setFocusBounds && setFocusBounds(bounds);
  return bounds;
};

// get property address from property object and set if we are passing setstate method otherwise just return it
export const setFocusBoundsThroughPropertyAddress = (property, setFocusBounds) => {
  const coordinates = get(property, 'reference_point.coordinates', []);
  const latLongs = [coordinates[1], coordinates[0]]; // || DEFAULT_LAT_LONG
  /* eslint-disable no-undef */
  const bounds = L.latLngBounds(latLongs, latLongs);
  setFocusBounds && setFocusBounds(bounds);
  return bounds;
};

export const getPropDetails = async (propertyId, setLat, setLong, setProperty, setErrorMessage) => {
  try {
    const propertyRes = await getLatLongByPropId(propertyId, setLat, setLong, DEFAULT_LATLONG);
    setProperty(propertyRes.data);
  } catch (error) {
    if (!isEmpty(error) && Object.keys(error.response.data).length > 0) {
      const errorMessageByCode = getErrorMessageByCode(error.response.data.code);
      setErrorMessage(errorMessageByCode);
    }
  }
};

export const getStringWithoutAccentsOrDiacritics = (value) => {
  return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

export const checkContractPermission = (contracts, permissionKey) => {
  const entitlementList = contracts || [];
  return entitlementList.some((item) => {
    const entitlements = item.entitlements || [];
    return entitlements.find((plan) => plan.key === permissionKey);
  });
};
