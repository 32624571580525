import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { iff } from 'utils/iff';
import { StyledModal } from './InfoCarousel.style';
import { InfoCarouselComponent } from './InfoCarousel';
import { history, URL_CONSTANTS } from '../../utils/history';
import { ModalWhatsNew, WhatsNewHelper } from './ModalWhatsNew';

export const InfoCarouselModal = (props) => {
  const {
    orgs,
    orgsList,
    user,
    propertiesList,
    termsSuccess,
    isNewSignedupUser,
    updateProfileSettings,
    fetchUserDetails,
    setWhatsNewIsVisible,
    isWhatsNewVisible
  } = props;
  const [modalVisible, setModalVisible] = useState(false);
  const [whatsNewModalVisible, setWhatsNewModalVisible] = useState(false);
  const [orgId, setOrgId] = useState('');

  /**
   * get orgId
   * to redirect user to hall of proprtes when welcome popup is closed
   * to redirect user to create property when clicked on create property button
   */
  useEffect(() => {
    if (user.auto_created_org_id && user.auto_created_org_id !== '') {
      setOrgId(user.auto_created_org_id);
    } else if (Array.isArray(orgs) && orgs.length > 0) {
      setOrgId(orgs[0]);
    }
  }, [setOrgId, user, orgs]);

  const handleCompleteTutorial = async () => {
    const userDetails = { ...user, opt_ins: undefined, tutorial_complete: true };
    userDetails.json_extended_attributes = {
      ...userDetails.json_extended_attributes,
      deck_tutorial_complete: true
    };
    await updateProfileSettings(userDetails, user.id, true);
    setModalVisible(false);
    sessionStorage.setItem(`tutorial_complete_${user.id}`, true);
    await fetchUserDetails();
  };

  useEffect(() => {
    const sessionTutorialComplete = sessionStorage.getItem(`tutorial_complete_${user.id}`);
    if (!sessionTutorialComplete && termsSuccess === true) {
      setModalVisible(get(user, 'tutorial_complete') === false);
    }
  }, [user, termsSuccess]);

  const handleWhatsNewComplete = async () => {
    setWhatsNewModalVisible(false);
    const userDetails = { ...user, opt_ins: undefined };
    const whatsNewSeenList = get(user.json_extended_attributes, 'whats_new_seen_items');
    userDetails.json_extended_attributes = {
      ...userDetails.json_extended_attributes,
      whats_new_seen_items: WhatsNewHelper(whatsNewSeenList).getUpdatedSeenWhatsNewList()
    };
    await updateProfileSettings(userDetails, user.id, true);
    await fetchUserDetails();
  };

  useEffect(() => {
    if (!modalVisible) {
      if (get(user, 'json_extended_attributes')) {
        const whatsNewSeenList = get(user.json_extended_attributes, 'whats_new_seen_items');
        const signedUpAt = get(user, 'created_at');
        const showMeWhatsNew = WhatsNewHelper(whatsNewSeenList, signedUpAt).showMeWhatsNew();
        const tutorialComplete = get(user, 'tutorial_complete');
        const sessionTutorialComplete = sessionStorage.getItem(`tutorial_complete_${user.id}`);
        if (showMeWhatsNew && (tutorialComplete || sessionTutorialComplete)) {
          setWhatsNewModalVisible(true);
        }
      }
    }
  }, [user, modalVisible]);

  return (
    <>
      <StyledModal
        centered
        width={592}
        footer={null}
        visible={modalVisible}
        closeIcon={iff(isNewSignedupUser, () => (
          <></>
        ))}
        onCancel={async () => {
          if (!isNewSignedupUser) {
            await handleCompleteTutorial();
            // if org id available, after closing the popup, redirect user to hall of properties
            if (orgId && orgId !== '') {
              history.push(URL_CONSTANTS.LANDING_ORGANIZATION({ orgId }));
              const delay = 5000;
              setTimeout(() => {
                const assistantIcon = document.getElementsByClassName('cw-assistant-icon')[0];
                if (assistantIcon) {
                  assistantIcon.click();
                }
              }, delay);
            }
          }
        }}
        maskClosable={false}>
        <InfoCarouselComponent
          {...props}
          orgId={orgId}
          setOrgId={setOrgId}
          handleCompleteTutorial={handleCompleteTutorial}
        />
      </StyledModal>
      <ModalWhatsNew
        whatsNewModalVisible={whatsNewModalVisible || isWhatsNewVisible}
        isWhatsNewVisibleGlobalState={isWhatsNewVisible}
        handleWhatsNewComplete={() => {
          if (whatsNewModalVisible) {
            handleWhatsNewComplete();
          }
          setWhatsNewIsVisible(false);
        }}
        user={user}
        orgs={orgs}
        orgsList={orgsList}
        propertiesList={propertiesList}
      />
    </>
  );
};

InfoCarouselModal.propTypes = {
  user: PropTypes.object,
  isNewSignedupUser: PropTypes.bool,
  updateProfileSettings: PropTypes.func,
  termsSuccess: PropTypes.bool.isRequired,
  fetchUserDetails: PropTypes.func,
  orgsList: PropTypes.array,
  orgs: PropTypes.array,
  propertiesList: PropTypes.object,
  isWhatsNewVisible: PropTypes.bool,
  setWhatsNewIsVisible: PropTypes.func.isRequired
};
