import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import {
  getAccountsTnCUrl,
  getAccountsVoucherRedeemUrl
} from './common-methods';
import { AppConstants } from './app.constants';

export const checkAccess = (termsSuccess: any, plans: any, terms: any, user: any, currentRoute?: any) => {
  const signedUpAt = get(user, 'opt_ins[0].accepted_on');
  // ignore check for redirecting to accounts portal for voucher for these routes
  const IGNORE_VOUCHER_CHECK = ['/app/deep-actions/activate-new-plan'];
  if (IGNORE_VOUCHER_CHECK.includes(currentRoute)) {
    return 1;
  }
  if (termsSuccess) {
    if (
      signedUpAt &&
      !IGNORE_VOUCHER_CHECK.includes(currentRoute) &&
      moment().diff(moment(signedUpAt), 'minutes') < AppConstants.SIGNUP_TIMER) {
      if (isEmpty(plans || [])) {
        window.location.href = getAccountsVoucherRedeemUrl();
        return;
      } else if (!isEmpty(plans || [])) {
        const baseAppPlanAttached = (plans || []).find(
          (plan: any) => plan.app.id === AppConstants.BASE_REF_ID
        );
        if (!baseAppPlanAttached) {
          window.location.href = getAccountsVoucherRedeemUrl();
          return;
        }
      }
    }
    //check if any unsigned terms are there
    for (const term of terms) {
      const checkForUnsignedTerm = term.plans.find((plan: any) => {
        return plan.signed === false;
      });
      if (checkForUnsignedTerm) {
        window.location.href = getAccountsTnCUrl();
      }
    }
    return 2;
  }
};
