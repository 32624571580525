import isEmpty from 'lodash/isEmpty';
import {
  FETCH_ORGANIZATIONS_SUCCESS,
  FETCH_ORGANIZATIONS_ERROR,
  FETCH_ORGANIZATIONS_LOADING,
  FETCH_ORGANIZATION_BY_ID_LOADING,
  FETCH_ORGANIZATION_BY_ID_SUCCESS,
  FETCH_ORGANIZATION_BY_ID_ERROR,
  SET_SELECTED_PROPERTY,
  CREATE_ORG_LOADING,
  CREATE_ORG_SUCCESS,
  CREATE_ORG_ERROR,
  SET_SELECTED_SEASONS,
  DELETE_PROPERTY_BY_ID_SUCCESS,
  DELETE_PROPERTY_BY_ID_LOADING,
  DELETE_PROPERTY_BY_ID_ERROR,
  CREATE_SEASON_LOADING,
  CREATE_SEASON_SUCCESS,
  CREATE_SEASON_ERROR,
  EDIT_SEASON_LOADING,
  EDIT_SEASON_SUCCESS,
  EDIT_SEASON_ERROR,
  DELETE_SEASON_BY_ID_SUCCESS,
  DELETE_SEASON_BY_ID_ERROR,
  DELETE_SEASON_BY_ID_LOADING,
  UPDATE_ORGANIZATION_LOADING,
  UPDATE_ORGANIZATION_ERROR,
  UPDATE_ORGANIZATION_SUCCESS,
  DELETE_ORGANIZATION_LOADING,
  DELETE_ORGANIZATION_ERROR,
  DELETE_ORGANIZATION_SUCCESS,
  UPLOAD_ORGANIZATION_AVATAR_LOADING,
  UPLOAD_ORGANIZATION_AVATAR_SUCCESS,
  UPLOAD_ORGANIZATION_AVATAR_ERROR,
  REMOVE_ORGANIZATION_AVATAR_LOADING,
  REMOVE_ORGANIZATION_AVATAR_SUCCESS,
  REMOVE_ORGANIZATION_AVATAR_ERROR,
  EDIT_ORG_LOADING,
  EDIT_ORG_SUCCESS,
  EDIT_ORG_ERROR,
  DELETE_ORGANIZATION_RESET
} from '../actions/types';

const initialState = {
  updating: false,
  updatingError: null,
  updatingSuccess: false,
  deleteOrgLoading: false,
  deleteOrgError: null,
  deleteOrgSuccess: false,
  uploadOrgAvatarLoading: false,
  uploadOrgAvatarError: null,
  uploadOrgAvatarSuccess: false,
  removeOrgAvatarLoading: false,
  removeOrgAvatarError: null,
  removeOrgAvatarSuccess: false,
  createSeasonSuccess: false,
  createSeasonError: null,
  editSeasonError: null,
  editSeasonSuccess: false,
  loading: false,
  data: [],
  error: null,
  selectedOrg: {},
  selectedProperty: {},
  selectedSeasons: [],
  selectedPropFields: [],
  org: {},
  loadingOrg: false,
  orgError: null,
  orgById: {}
};

export const orgs = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ORGANIZATIONS_SUCCESS:
      const { payload } = action;
      const selectedOrg = (payload && payload[0]) || {};
      const selectedProperty = selectedOrg.properties && selectedOrg.properties[0];

      return {
        ...state,
        data: payload,
        selectedOrg,
        selectedProperty,
        selectedSeasons: [],
        loading: false,
        createSeasonSuccess: false,
        createSeasonError: null,
        editSeasonSuccess: false,
        editSeasonError: null
      };
    case FETCH_ORGANIZATIONS_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false
      };
    case FETCH_ORGANIZATIONS_LOADING:
      return {
        ...state,
        data: [],
        selectedOrg: {},
        selectedProperty: {},
        loading: true
      };
    case SET_SELECTED_PROPERTY:
      return {
        ...state,
        selectedProperty: action.payload
      };
    case CREATE_ORG_SUCCESS:
      return {
        ...state,
        org: action.payload,
        loadingOrg: false
      };
    case CREATE_ORG_ERROR:
      return {
        ...state,
        orgError: action.error,
        loadingOrg: false
      };
    case CREATE_ORG_LOADING:
      return {
        ...state,
        loadingOrg: true
      };

    case EDIT_ORG_SUCCESS:
      const updatedOrg = state.data.map((item) => {
        if (item.id !== action.payload.data.id) {
          return item;
        }
        return {
          ...item,
          ...action.payload.data
        };
      });
      return {
        ...state,
        data: updatedOrg,
        loadingEditOrg: false
      };
    case EDIT_ORG_ERROR:
      return {
        ...state,
        orgEditError: action.error,
        loadingEditOrg: false
      };
    case EDIT_ORG_LOADING:
      return {
        ...state,
        loadingEditOrg: true
      };

    case SET_SELECTED_SEASONS:
      const selectedSeasons = action.payload || [];
      return {
        ...state,
        selectedSeasons
      };
    case FETCH_ORGANIZATION_BY_ID_SUCCESS:
      return {
        ...state,
        orgById: action.payload,
        loading: false
      };
    case FETCH_ORGANIZATION_BY_ID_LOADING:
      return {
        ...state,
        loading: true
      };
    case FETCH_ORGANIZATION_BY_ID_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false
      };
    case DELETE_PROPERTY_BY_ID_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case DELETE_PROPERTY_BY_ID_SUCCESS:
      const orgById = state.data.find((d) => d.id === action.payload.orgId);
      if (orgById) {
        orgById.properties = orgById.properties.filter(
          (property) => property.id !== action.payload.propertyId
        );

        const filtereOrgs = state.data.filter((o) => o.id !== action.payload.orgId);
        filtereOrgs.push(orgById);
        return {
          ...state,
          data: filtereOrgs,
          loading: false
        };
      }

      return {
        ...state,
        loading: false
      };

    case DELETE_PROPERTY_BY_ID_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false
      };
    case DELETE_SEASON_BY_ID_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case DELETE_SEASON_BY_ID_SUCCESS:
      const orgByIdSeason = state.data.find((d) => d.id === action.payload.orgId);
      if (orgByIdSeason) {
        orgByIdSeason.seasons = orgByIdSeason.seasons.filter(
          (season) => season.id !== action.payload.seasonId
        );

        const filtereOrgs = state.data.filter((o) => o.id !== action.payload.orgId);
        filtereOrgs.push(orgByIdSeason);

        return {
          ...state,
          data: filtereOrgs,
          loading: false
        };
      }

      return {
        ...state,
        loading: false
      };
    case DELETE_SEASON_BY_ID_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case UPDATE_ORGANIZATION_LOADING:
      return {
        ...state,
        updating: true,
        updatingError: null,
        updatingSuccess: false
      };

    case UPDATE_ORGANIZATION_ERROR:
      return {
        ...state,
        updatingError: action.error,
        updating: false
      };

    case UPDATE_ORGANIZATION_SUCCESS:
      const updatedOrgs = state.data.map((item) => {
        if (item.id !== action.payload.data.id) {
          // This isn't the item we care about - keep it as-is
          return item;
        }
        // Otherwise, this is the one we want - return an updated value
        return {
          ...item,
          ...action.payload.data
        };
      });
      return {
        ...state,
        data: updatedOrgs,
        updatingSuccess: true,
        updating: false
      };

    case DELETE_ORGANIZATION_LOADING:
      return {
        ...state,
        deleteOrgLoading: true,
        deleteOrgError: null,
        deleteOrgSuccess: false
      };

    case DELETE_ORGANIZATION_ERROR:
      return {
        ...state,
        deleteOrgError: action.error,
        deleteOrgLoading: false
      };

    case DELETE_ORGANIZATION_SUCCESS:
      const filteredOrgs = state.data.filter((item) => item.id !== action.payload.id);
      return {
        ...state,
        data: filteredOrgs,
        deleteOrgSuccess: true,
        deleteOrgLoading: false
      };

    case DELETE_ORGANIZATION_RESET:
      return {
        ...state,
        deleteOrgSuccess: false,
        deleteOrgLoading: false,
        deleteOrgError: null
      };

    case UPLOAD_ORGANIZATION_AVATAR_LOADING:
      return {
        ...state,
        uploadOrgAvatarLoading: true,
        uploadOrgAvatarSuccess: false,
        uploadOrgAvatarError: null
      };

    case UPLOAD_ORGANIZATION_AVATAR_ERROR:
      return {
        ...state,
        uploadOrgAvatarLoading: false,
        uploadOrgAvatarError: action.error
      };

    case UPLOAD_ORGANIZATION_AVATAR_SUCCESS:
      const upLoadedAvatarOrg = state.data.map((item) => {
        if (item.id !== action.payload.org.id) {
          return item;
        }

        return {
          ...item,
          picture_url: action.payload.res.data.avatar_url
        };
      });

      return {
        ...state,
        data: upLoadedAvatarOrg,
        uploadOrgAvatarLoading: false,
        uploadOrgAvatarSuccess: true
      };

    case REMOVE_ORGANIZATION_AVATAR_LOADING:
      return {
        ...state,
        removeOrgAvatarLoading: true,
        removeOrgAvatarSuccess: false,
        removeOrgAvatarError: null
      };

    case REMOVE_ORGANIZATION_AVATAR_ERROR:
      return {
        ...state,
        removeOrgAvatarLoading: false,
        removeOrgAvatarError: action.error
      };

    case REMOVE_ORGANIZATION_AVATAR_SUCCESS:
      const removeAvatarOrg = state.data.map((item) => {
        if (item.id !== action.payload.id) {
          return item;
        }

        return {
          ...item,
          picture_url: null
        };
      });

      return {
        ...state,
        data: removeAvatarOrg,
        removeOrgAvatarLoading: false,
        removeOrgAvatarSuccess: true
      };

    case CREATE_SEASON_LOADING:
      return {
        ...state,
        loading: true,
        createSeasonError: null
      };

    case CREATE_SEASON_SUCCESS:
      const org = state.data.find((o) => o.id === action.payload.org_id);
      if (!isEmpty(org)) {
        org.seasons.push(action.payload);
      }

      return {
        ...state,
        data: [...state.data, org || {}],
        loading: false,
        createSeasonSuccess: true
      };

    case CREATE_SEASON_ERROR:
      return {
        ...state,
        loading: false,
        createSeasonError: action.error
      };

    case EDIT_SEASON_LOADING:
      return {
        ...state,
        loading: true,
        editSeasonError: null
      };

    case EDIT_SEASON_SUCCESS:
      return {
        ...state,
        loading: false,
        editSeasonSuccess: true
      };

    case EDIT_SEASON_ERROR:
      return {
        ...state,
        loading: false,
        editSeasonError: action.error
      };

    default:
      return state;
  }
};
