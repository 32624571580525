import 'leaflet/dist/leaflet';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet/dist/leaflet.css';
import 'leaflet-editable/src/Leaflet.Editable';
import 'leaflet.path.drag/src/Path.Drag';
import 'leaflet-draw';
import 'leaflet.gridlayer.googlemutant/Leaflet.GoogleMutant';

export const mapInit = () => {
  const script = document.createElement('script');
  const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
  script.src = `https://maps.googleapis.com/maps/api/js?v=3.46&key=${apiKey}&libraries=places&callback=initAutocomplete`;
  script.async = 'true';
  script.defer = 'true';
  document.body.appendChild(script);

  if (window.initAutocomplete === undefined) {
    window.initAutocomplete = () => null;
  }
};
mapInit();
